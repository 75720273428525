import React, { useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProtectedRoutes from './WebRoutes/ProtectedRoutes';
import LoginRoutes from './WebRoutes/LoginRoutes';

import { AuthInfo } from '../interfaces/ads/auth.info.interface';

export const AsistenciaMedicaWebRouter = () => {

	const [sessionInfo, setSessionInfo] = useState<AuthInfo>()
	
	const isLogged = !!sessionInfo
	// LoginRoutes es un mock para simular el sistema de rutas protegidas

	return (        
		<BrowserRouter>
			<Switch>
				<Route path="/" render={(props) =>
				isLogged ? 
					(	
						<ProtectedRoutes authInfo={sessionInfo} {...props} setSessionInfo={setSessionInfo} />
					) 
					: 
					(
						// Descomentar para pruebas ysocial en local
						// <LoginRoutesMock setSessionInfo={setSessionInfo} {...props} />
						<LoginRoutes setSessionInfo={setSessionInfo} {...props} />
					)
				} />
			</Switch>
		</BrowserRouter>
	)
	
}
