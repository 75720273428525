import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useProximidad } from '../../../hooks/useProximidad';
import { YsocialContext } from '../../../contexts/YsocialContext';
import { Direccion } from '../../../interfaces/direccion.interface';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';
import { updateSocioPrincipal } from '../../../api/socioPrincipalService';
import { Button, Modal, TextLink } from 'app-principal-componentes-visuales';
import DatosUbicacion from '../../../components/Cards/DatosUbicacion/DatosUbicacion';
import LocalidadError from '../../../components/Modals/LocalidadError/LocalidadError';
import { YsocialActionType } from '../../../interfaces/global-state/ysocial.action.type.enum';
import EliminarDomicilio from '../../../components/Modals/EliminarDomicilio/EliminarDomicilio';
import ProximidadUbicacion from '../../../components/Modals/ProximidadUbicacion/ProximidadUbicacion';
import { DATOS_CONTACTOS_YSOCIAL, FORMULARIO_DOMICILIO, RESUMEN_YSOCIAL, SELECCIONAR_DIRECCION_YSOCIAL } from '../../../utils/constants';
import ErrorPage from '../../ErrorPage/ErrorPage';
import './SeleccionarDomicilioYsocial.scss';
import DatosUbicacionYsocial from '../../../components/WebYsocial/Cards/DatosUbicacion/DatosUbicacionYsocial';

const SeleccionarDomicilioYsocial = () => {

    const history = useHistory();
    const { ysocial, useRequest, isWeb, dispatchYsocial } = useContext(YsocialContext);
    const { apiRequest } = useRequest();
    const { usuario } = ysocial;
    const [showFormularioDomicilioModal, setFormularioDomicilioModal] = useState(false);
    const [showDeleteDirectionModal, setDeleteDirectionModal] = useState(false);
    const [selectedDirection, setSelectedDirection] = useState({});
    const [direccionesSocio, setDireccionesSocio] = useState(usuario.direcciones);
    const [editPlace, setEditPlace] = useState(false);
    const [modalDelete, setModalDelete] = useState('');
    const [selectedOption, setSelectedOption] = useState<Direccion>(null);
    const [editarFormulario, setEditarFormulario] = useState(false);

    const { error,
        localidadError,
        proximidadModal,
        direccionProxima,
        onClickContinuar,
        handleClickCambiarDireccionProximidad,
        handleClickValidarProximidad,
        setError,
        setLocalidadError,
        setProximidadModal } = useProximidad();

    useEffect(() => {
        setDireccionesSocio(usuario.direcciones);
    }, [usuario.direcciones])

    useEffect(() => {
        let seleccionada = usuario.direcciones.find((direccion) => direccion.seleccionado === true);
        setSelectedOption(seleccionada);
    }, [])

    /**
     * Encargado de abrir/cerrar el modal del formulario
     */
    const openFormularioModal = (selectedDirection) => {
        setSelectedDirection(selectedDirection);
        history.push(FORMULARIO_DOMICILIO, {
            fromState: "seleccionardomicilio"
        })
    }

    /**
     * Encargado de abrir/cerrar el modal de eliminacion de direcciones
     */
    const openDeleteDirection = (selectedDirection, modal) => {
        setModalDelete(modal);
        setSelectedDirection(selectedDirection);
        setDeleteDirectionModal(!showDeleteDirectionModal);
    }

    // const editLocation = () => {
    //     //TODO navegar a nueva page de formulario
    //     setEditPlace(!editPlace);
    //     history.push(FORMULARIO_DOMICILIO, {
    //         editPlace
    //     })
    // }

    /**
     * Metodo que elimina la direccion seleccionada del socio principal
     */
    const deleteDirection = (direccionId) => {
        const direccionFiltrada = direccionesSocio.filter(direccion => {
            return direccionId !== direccion.id
        });

        if (direccionId === selectedOption.id && direccionFiltrada.length !== 0) {

            const nuevaSeleccion = { ...direccionFiltrada[direccionFiltrada.length - 1], seleccionado: true };
            setSelectedOption(nuevaSeleccion);

            const direccionesActualizadas = direccionFiltrada.map(direccion =>
                direccion.id === nuevaSeleccion.id ? nuevaSeleccion : direccion
            );

            if (direccionesActualizadas.length > 0) {
                setDireccionesSocio(direccionesActualizadas);
                direccionSeleccionada(direccionesActualizadas);
            }
        } else {
            setDireccionesSocio(direccionFiltrada);
            direccionSeleccionada(direccionFiltrada);
        }

        setDeleteDirectionModal(!showDeleteDirectionModal);
    }

    const returnSelectedDirection = (seleccionado) => {
        setSelectedOption(seleccionado);
    };

    const handleBack = async () => {
        await direccionSeleccionada();
        actualizarSocioPrincipal();
        history.push(DATOS_CONTACTOS_YSOCIAL);
    }

    const direccionSeleccionada = (deleteDirection?) => {
        let direccionAux: Direccion[] = deleteDirection ? deleteDirection : direccionesSocio;

        if(deleteDirection) foundSelected(deleteDirection);

        setDireccionesSocio(direccionAux);
        dispatchYsocial({
            type: YsocialActionType.actualizarDireccion,
            direcciones: direccionAux
        })
    }

    const foundSelected = (direcciones: Direccion[]) => { 
        let direccionSeleccionada = direcciones.find((address) => address.seleccionado === true)
        if(!direccionSeleccionada) direccionSeleccionada = { ...direcciones[direcciones.length -1 ], seleccionado: true }
        return direccionSeleccionada
    }

    const actualizarSocioPrincipal = async () => {
        try {
            await updateSocioPrincipal(usuario, apiRequest);
            console.info("Se actualizo correctamente los datos del socio");
        } catch (error) {
            console.error(`Hubo un error al persistir el socio principal ${error}`);
        }
    };

    if (error) {
        return <ErrorPage setError={setError} />
    }

    return (
        <>
            {direccionesSocio.length === 0 ?
                <PageWrapper
                    pageClassName='ysocial-direcciones'
                    path={SELECCIONAR_DIRECCION_YSOCIAL}
                    socio={ysocial.usuario}
                    handlerHandleBack={handleBack}
                    notCancel={false}
                >
                    <IonRow>
                        <IonCol size='12' className='sin-domicilio'>
                            <Button
                                className='button--ripple'
                                colorBlack
                                label="No tenés domicilios registrados"
                            />
                        </IonCol>
                        <IonCol size='12' className='direcciones__col--padding'>
                            <TextLink
                                onClick={openFormularioModal}
                                right
                                texto="Agregar un nuevo domicilio"
                                id="nuevo-domicilio"
                            />
                        </IonCol>
                    </IonRow>
                </PageWrapper>
                :
                <PageWrapper
                    pageClassName='asistencia-medica-direcciones'
                    path={SELECCIONAR_DIRECCION_YSOCIAL}
                    socio={ysocial.usuario}
                    handlerHandleBack={handleBack}
                    className='direcciones_container'
                    notCancel={true}
                    noPadding={true}
                >
                    <IonGrid>
                        <IonRow>
                            <IonCol size='12'>
                                <DatosUbicacionYsocial
                                    openFormularioModal={openFormularioModal}
                                    direcciones={direccionesSocio}
                                    openDeleteDirection={openDeleteDirection}
                                    returnSelectedDirection={returnSelectedDirection}
                                    setSelectedOption={setSelectedOption}
                                />
                                <TextLink
                                    className='direcciones_padding'
                                    onClick={openFormularioModal}
                                    right
                                    texto="Agregar un nuevo domicilio"
                                    id="nuevo-domicilio"
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonRow className='direcciones_padding'>
                        <Button
                            id="seleccionar-domicilio-continuar"
                            className='seleccionar-domicilio__button'
                            key="continue_button"
                            primary
                            onClick={() => handleClickValidarProximidad(selectedOption) }
                            label={"Continuar"}
                            disable={localidadError}
                        />
                    </IonRow>
                </PageWrapper>
            }

            <Modal
                className='modal_web'
                hideCloseBtn
                //@ts-ignore
                component={<EliminarDomicilio openDeleteDirection={openDeleteDirection} selectedDirection={selectedDirection} deleteDirection={deleteDirection} modalDelete={modalDelete} isWeb={isWeb} />}
                setShowModal={setDeleteDirectionModal}
                showModal={showDeleteDirectionModal}
                id="eliminar-domicilio-modal"
            />


            <Modal
                screen60
                className="modal_web"
                component={
                    <ProximidadUbicacion
                        onClickContinuar={() => onClickContinuar(selectedOption)}
                        onClickCambiarDireccion={handleClickCambiarDireccionProximidad}
                        direccionMasProxima={direccionProxima}
                    />
                }
                setShowModal={setProximidadModal}
                showModal={proximidadModal}
                id="modal-proximidad-ubicacion"
            />

            <Modal
                screen60
                hideCloseBtn
                //@ts-ignore
                component={
                    <LocalidadError
                        setLocalidadError={setLocalidadError}
                        setEditarFormulario={setEditarFormulario}
                    />
                }
                setShowModal={setLocalidadError}
                showModal={localidadError}
                id="modal-localidad-error"
            />
        </>
    )
}

export default SeleccionarDomicilioYsocial