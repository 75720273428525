import React from 'react';
import { Header } from 'app-principal-componentes-visuales';
import { useHistory } from 'react-router-dom';
import { CANCELAR_SOLICITUD, CANCELAR_SOLICITUD_YSOCIAL, HOME } from '../../../utils/constants';

interface AsistenciaHeaderProps {
    titulo: string,
    showBackButton: boolean,
    showCancelPage: boolean,
    notCancel?:boolean,
    handlerHandleBack?: () => void
}

const AsistenciaHeader = ({titulo, showBackButton, showCancelPage, notCancel, handlerHandleBack} : AsistenciaHeaderProps) => {
    
    const navigation = useHistory();
    const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));

    const handleBack = () => {
        navigation.goBack();
    }

    const handleClose = () => {
        if(showCancelPage){
            if (ysocial.isYsocial) {
                //direccion a cancelar de ysocial
                navigation.push(CANCELAR_SOLICITUD_YSOCIAL, { from: navigation.location.pathname})
            } else {
                navigation.push(CANCELAR_SOLICITUD, { from: navigation.location.pathname})
            }
        } else {
            navigation.push(HOME, { from: navigation.location.pathname})
        }
    }

    return (
        <Header
            className='asistencia__header'
            titulo={titulo}
            colorWhite
            fondoCeleste
            closeBtn={!notCancel}
            backBtn={!showBackButton}
            headerTramites
            onClickBackBtn={handlerHandleBack ? handlerHandleBack : handleBack}
            onClickCloseBtn={handleClose}
            id="header-asistencia-medica-header"
        />
    )
}

export default AsistenciaHeader;