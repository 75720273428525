import React, { useContext, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { YsocialContext } from "../../../contexts/YsocialContext";
import { Button, InputText, Subtitulo, Titulo } from "app-principal-componentes-visuales";
import { YsocialActionType } from "../../../interfaces/global-state/ysocial.action.type.enum";
import { DATOS_CONTACTOS_YSOCIAL, FORMULARIO_CONTACTO_YSOCIAL } from "../../../utils/constants";
import { updateSocioPrincipal } from "../../../api/socioPrincipalService";
import { capitalizeString } from "../../../utils/utils";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { updateSocioFamiliar } from "../../../api/socioFamiliarService";
import "./FormularioContactoYsocial.scss";

const FormularioContactoYsocial = ({ history }) => {

  const { socio } = history.location.state;
  const { useRequest, dispatchYsocial } = useContext(YsocialContext);
  const { apiRequest } = useRequest();
  const [phoneNumber, setPhoneNumber] = useState(socio.telefono);
  const [email, setEmail] = useState(socio.email);
  const [validEmail, setValidEmail] = useState(null);
  const [validPhone, setValidPhone] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);

  const phoneIsValid = (phoneNumberValue) => {
    const re = /^(?![0])[0-9]{10}$/;
    const phoneIsValid = re.test(String(phoneNumberValue))
    return phoneIsValid
  };

  const emailIsValid = (emailValue) => {
    const re = /^[\w-\.]+@([\w-]+\.)+[a-z]{2,3}$/;
    const emailIsValid = re.test(String(emailValue))
    return emailIsValid
  };

  const handleEmailInput = (str) => {
    setEmail(str.detail.value);
    setValidEmail(emailIsValid(str.detail.value))
  };

  const handlePhoneInput = (str) => {
    setPhoneNumber(str.detail.value);
    setValidPhone(phoneIsValid(str.detail.value))
  };

  const buttonValid = () => {
    return emailIsValid(email) && phoneIsValid(phoneNumber)
  }

  const handleSaveChanges = async () => {
    setSavingChanges(true);
    let socioAux = { ...socio, telefono: phoneNumber, email: email }
    
    dispatchYsocial({
      type: YsocialActionType.actualizarDatosContactoSocioSeleccionado,
      datosContacto: { telefono: socioAux.telefono, email: socioAux.email }
    });
    
    try {
      socioAux?.contratos ? await updateSocioPrincipal(socioAux, apiRequest) : await updateSocioFamiliar(socioAux, apiRequest);

    } catch (error) {
      console.error("Se produjo un error al actualizar los datos del socio", error);
    }
    history.push(DATOS_CONTACTOS_YSOCIAL)
  }

  return (
    <>
      <PageWrapper
        socio={socio}
        pageClassName="datos-ysocial"
        path={FORMULARIO_CONTACTO_YSOCIAL}
        notCancel={false}
        notBack={false}>
        <IonRow>
          <IonCol size="12">
            <Titulo
              titulo={`Completa los datos para esta asistencia ${capitalizeString(
                `${socio.apellidos} ${socio.nombre}`
              )}`}
              id="titulo-asistencia-ysocial"
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <Subtitulo
              titulo="Datos de contacto"
              txt16
              id="subtitulo-contacto-ysocial"
            />
            <Subtitulo
              titulo="Nos permitirán contactarte en caso de que sea necesario para resolver esta solicitud."
              txt14
              id="subtitulo-solicitud"
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12' className='input-telefono'>
            <InputText
              id='editar-datos-input-telefono'
              assistiveText={validPhone === false ? '' : 'Completá con código de área sin 0 y número sin 15'}
              errorLink={validPhone === null ? false : !validPhone}
              errorText={validPhone === null ? '' : validPhone ? '' : "El télefono debe tener 10 dígitos y no debe comenzar con 0."}
              label="Teléfono celular"
              normalLink
              onIonChange={handlePhoneInput}
              placeholder={`11 · 1111 · 1111`}
              type="number"
              inputmode="number"
              maxLength={10}
              value={phoneNumber}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            <InputText
              id='editar-datos-input-email'
              errorLink={validEmail === null ? false : !validEmail}
              errorText={validEmail === null ? '' : validEmail ? '' : "Ingresá un email válido."}
              label="Email"
              normalLink
              onIonChange={handleEmailInput}
              placeholder="Ingresá tu email"
              type="text"
              value={email}
            />
          </IonCol>
        </IonRow>
        <IonRow className="row-form-continuar">
          <IonCol size="12">
            <Button
              id='guardar-datos-contacto'
              disable={!buttonValid()}
              label={savingChanges ? "Guardando cambios" : "Guardar cambios"}
              onClick={handleSaveChanges}
              waiting={savingChanges}
              primary
              className='boton-guardar-cambios'
            />
          </IonCol>
        </IonRow>
      </PageWrapper>
    </>
  )
}

export default FormularioContactoYsocial;