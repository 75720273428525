import { getCasosEnCurso } from "../../api/salusService";
import { filtrarCasosEnHistorial, filtrarCasosEnCurso } from "../serviciosEnCursoHelper";
import { YsocialActionType } from "../../interfaces/global-state/ysocial.action.type.enum";

export const solicitarServiciosEnCurso = (usuario, dispatchYsocialWeb, storageManager, apiRequest) => {
    return new Promise<any>(async (resolve, reject) => {
        let casosFiltrados;

        try {
            let casosPorClasificacion;
            const casos = await getCasosEnCurso(usuario?.ic, apiRequest);
            if (casos?.status === 200) {
                storageManager.setDataStorage("casosEnCurso", JSON.stringify(casos?.data));
                const casosHistorial = await filtrarCasosEnHistorial(casos.data, storageManager);
                casosFiltrados = await filtrarCasosEnCurso(casosHistorial);

                dispatchYsocialWeb({
                    type: YsocialActionType.actualizarCasosEnCurso,
                    casosEnCurso: casosFiltrados
                });

                const socioConClasificaciones = {};
                casosFiltrados.forEach(caso => {
                    const nombre = caso.principal.nombre;
                    if (!socioConClasificaciones[nombre]) {
                        socioConClasificaciones[nombre] = {
                            nombre,
                            clasificaciones: [],
                        };
                    }

                    socioConClasificaciones[nombre].clasificaciones.push(caso);
                });

                casosPorClasificacion = Object.values(socioConClasificaciones);

                resolve(casosPorClasificacion);
            } else {
                reject("error");
            }
        } catch (error: any) {
            console.error(`Ysocial - Hubo un problema asociado al llamado de servicios en curso ${error}`);
            reject(error);
        }
    })
}