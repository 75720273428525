import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { RESUMEN_YSOCIAL } from "../utils/constants";
import { YsocialContext } from "../contexts/YsocialContext";
import { Direccion } from "../interfaces/direccion.interface";
import { checkearGeolocalizacion } from "../helpers/googleApi";
import { eliminarLocalidadStorage } from "../utils/ubicacionUtils";
import { updateDirection } from "../helpers/WebYsocial/direccionesHelper";
import { YsocialActionType } from "../interfaces/global-state/ysocial.action.type.enum";

export const useProximidad = () => {
    const history = useHistory();
    const { ysocial, storageManager, dispatchYsocial, useRequest } = useContext(YsocialContext);
    const { apiRequest } = useRequest();
    const { usuario } = ysocial;

    const [proximidadModal, setProximidadModal] = useState(false);
    const [direccionProxima, setDireccionProxima] = useState(null);
    const [localidadError, setLocalidadError] = useState(false);
    const [error, setError] = useState(false);

    /**
     * Encargado de validar si existe una direccion mas cercana
     * @param selectedDirection 
     */
    const handleClickValidarProximidad = (selectedDirection) => {
        checkearGeolocalizacion(usuario.direcciones, selectedDirection, storageManager).then(resultado => {
            if (resultado) {
                setDireccionProxima(resultado);
                setProximidadModal(true);
            } else {
                onClickContinuar(selectedDirection);
            }
        }).catch((error) => {
            console.error("Ocurrió un problema al verificar la geolocalización", error);
            onClickContinuar(selectedDirection);
        })
    }

    /**
     * Actualiza la direccion seleccionada por proximidad
     */
    const handleClickCambiarDireccionProximidad = () => {
        let direccionAux: Direccion[] = usuario.direcciones;
        direccionAux.forEach(direccion => {
            direccion.seleccionado = (direccion.id === direccionProxima.id)
        });

        const foundAddress = foundSelected(direccionAux);
        updateDirection(direccionAux, usuario, apiRequest, dispatchYsocial).then(() => {
            onClickContinuar(foundAddress);
        }).catch(() => {
            setError(true);
        })
    }

    const onClickContinuar = (foundAddress = null) => {
        let dirSeleccionada = foundAddress ? foundAddress : foundSelected(usuario.direcciones);

        if (dirSeleccionada.provincia === 'BUENOS AIRES' && (dirSeleccionada.localidad === 'CIUDAD AUTONOMA BUENOS AIRES' || dirSeleccionada.localidad === 'CAPITAL FEDERAL')) {
            if (!localidadError) {
                eliminarLocalidadStorage(storageManager);
                setLocalidadError(true);
            }
        } else {
            actualizarSocioPrincipal(dirSeleccionada);

            dispatchYsocial({
                type: YsocialActionType.actualizarDireccionSeleccionada,
                direccionSeleccionada: dirSeleccionada
            })

            history.push(RESUMEN_YSOCIAL, { dirSeleccionada });
        }
    }

    const foundSelected = (direcciones) => { return direcciones.find((address) => address.seleccionado === true)}

    const actualizarSocioPrincipal = (dirSeleccionada) => {
        let direccionesAux = usuario.direcciones;
    
        direccionesAux.forEach(direccion => {
            direccion.seleccionado = (direccion.id === dirSeleccionada.id)
        });
        
        let user = { ...usuario, direcciones: direccionesAux }

        dispatchYsocial({
            type: YsocialActionType.actualizarUsuario,
            socio: user
        })
    }

    return {
        error,
        localidadError,
        proximidadModal,
        direccionProxima,
        handleClickValidarProximidad,
        handleClickCambiarDireccionProximidad,
        onClickContinuar,
        setError,
        setLocalidadError,
        setProximidadModal
    }
}