import { CABA, CAPITAL } from "../../utils/constants";
import { Socio } from "../../models/socioPrincipal.model";
import { updateSocioPrincipal } from "../../api/socioPrincipalService";
import { YsocialActionType } from "../../interfaces/global-state/ysocial.action.type.enum";

export const updateDirection = (direcciones, socioPrincipal, apiRequest, dispatchYsocial) => {
    return new Promise((resolve, reject) => {
        let socio: Socio = socioPrincipal;
        let direccionesAux = direcciones.map((direc) => {
            if (direc.provincia === CABA) {
                return ({ ...direc, provincia: CAPITAL })
            }
            return direc;
        })

        socio.direcciones = direccionesAux;

        dispatchYsocial({
            type: YsocialActionType.actualizarDireccion,
            direcciones: direcciones
        })

        updateSocioPrincipal(socio, apiRequest).then((response) => {
            console.info('Se actualizo correctamente los datos del socio');
            resolve(response);
        }).catch(error => {
            console.error(`Hubo un error al persistir el socio principal ${error}`);
            reject(error);
        })
    })
}