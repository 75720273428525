import { AxiosResponse } from "axios";
import { CrearDerivarCasoYsocial } from "../../interfaces/ysocial/crearDerivarCasoYsocial.interface";
import { BACKEND_ENDPOINT, CASOS_SERVICIOS_MULTIPLES, SERVICIO_MULTIPLE } from "../../utils/constants";
import { Casos } from "../../models/caso.model";

/**
 * Invoca el servicio de creacion de casos multiples en el Backend
 */
export const sendCasoYsocial = (servicioYsocial: CrearDerivarCasoYsocial, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT() + SERVICIO_MULTIPLE;
        const method = 'POST';
        const data = servicioYsocial;
        const options = {
            url,
            method,
            data
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error: any) => reject(error));
    });
}

/**
 * Invoca el servicio de para guardar los casos con exitos
 */
export const saveCasoServicioYsocial = (casos: Casos, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const headers = {
            "Content-Type" : "application/json"
        }
        const url = BACKEND_ENDPOINT() + CASOS_SERVICIOS_MULTIPLES;
        const method = 'POST';
        const data = casos;
        const options = {
            url,
            method,
            data,
            headers
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error: any) => reject(error));
    });
}
