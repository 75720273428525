import React, { useEffect, useState } from 'react'
import { HOME, SELECCIONAR_SOCIO, FACTORES_RIESGO, VERIFICAR_DATOS_CONTACTO, VERIFICAR_DATOS_UBICACION, SINTOMAS, PREGUNTAS, RESUMEN, PEDIDO_SERVICIO, ASISTENCIA_CREADA, CANCELAR_SOLICITUD, SERVICIO_EN_CURSO, HISTORIAL_ATENCIONES, DETALLE_CASO, TELEFONOS_PAGE, FEEDBACK_SERVICIO_EN_CURSO } from '../../utils/constants'
import { Switch, Route, Redirect, useHistory } from 'react-router'
import FeedbackServicioEnCurso from '../../pages/FeedbackServicioEnCurso/FeedbackServicioEnCurso'
import AsistenciaSolicitada from '../../pages/AsistenciaSolicitada/AsistenciaSolicitada'
import SeleccionarDomicilio from '../../pages/SeleccionarDomicilio/SeleccionarDomicilio'
import VerificarDatosContacto from '../../pages/DatosContacto/VerificarDatosContacto'
import HistorialAtenciones from '../../pages/HistorialAtenciones/HistorialAtenciones'
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext'
import { AsistenciaMedica } from '../../pages/AsistenciaMedica/AsistenciaMedica'
import SeleccionarSocio from '../../pages/SeleccionarSocio/SeleccionarSocio'
import ServicioEnCurso from '../../pages/ServicioEnCurso/ServicioEnCurso'
import PedidoServicio from '../../pages/PedidoServicio/PedidoServicio'
import { useAsistenciaMedica } from '../../hooks/useAsistenciaMedica'
import TelefonosPage from '../../pages/TelefonosPage/TelefonosPage'
import Antecedentes from '../../pages/Antecedentes/Antecedentes'
import SplashScreen from '../../pages/SplashScreen/SplashScreen'
import DetalleCaso from '../../pages/DetalleCaso/DetalleCaso'
import { storageManager } from '../../helpers/dataProvider'
import ErrorPage from '../../pages/ErrorPage/ErrorPage'
import Preguntas from '../../pages/Preguntas/Preguntas'
import { useRequest } from '../../hooks/webUseRequest'
import Cancelar from '../../pages/Cancelar/Cancelar'
import Sintomas from '../../pages/Sintomas/Sintomas'
import Resumen from '../../pages/Resumen/Resumen'
import { setupData } from '../../utils/setupGA'
import { Environment } from '../../environment/Environment'

const ProtectedAsistenciaMedicaWeb = ({ authInfo, setSessionInfo }) => {

    const history = useHistory()
    const [error, setError] = useState(false)
    const { apiRequest } = useRequest(history, setSessionInfo)

    const {
        asistenciaMedica,
        dispatchAsistenciaMedica
    } = useAsistenciaMedica(authInfo, true, storageManager, useRequest, setError)

    if (error) {
        return <ErrorPage setError={setError} isWebProp={true} />
    }

    if (!asistenciaMedica.usuario) {
        return <SplashScreen />
    }

    return (
        <AsistenciaMedicaContext.Provider value={{
            asistenciaMedica,
            dispatchAsistenciaMedica,
            storageReady: true,
            storageManager,
            dataControlDeVersiones: {},
            useRequest: () => ({ apiRequest: (options, retry) => apiRequest(options, retry) }),
            setupData,
            authInfo,
            isWeb: true
        }}>
            <Switch>
                <Route exact path={HOME} component={AsistenciaMedica} />
                <Route exact path={SELECCIONAR_SOCIO} component={SeleccionarSocio} />
                <Route exact path={FACTORES_RIESGO} component={Antecedentes} />
                <Route exact path={VERIFICAR_DATOS_CONTACTO} component={VerificarDatosContacto} />
                <Route exact path={VERIFICAR_DATOS_UBICACION} component={SeleccionarDomicilio} />
                <Route exact path={SINTOMAS} component={Sintomas} />
                <Route exact path={PREGUNTAS} component={Preguntas} />
                <Route exact path={RESUMEN} component={Resumen} />
                <Route exact path={PEDIDO_SERVICIO} component={PedidoServicio} />
                <Route exact path={FEEDBACK_SERVICIO_EN_CURSO} component={ FeedbackServicioEnCurso } />
                <Route exact path={ASISTENCIA_CREADA} component={AsistenciaSolicitada} />
                <Route exact path={CANCELAR_SOLICITUD} component={Cancelar} />
                <Route exact path={SERVICIO_EN_CURSO} component={ServicioEnCurso} />
                <Route exact path={HISTORIAL_ATENCIONES} component={HistorialAtenciones} />
                <Route exact path={DETALLE_CASO} component={DetalleCaso} />
                <Route exact path={TELEFONOS_PAGE} component={TelefonosPage} />
                <Route exact path="/" render={() => <Redirect to={HOME} />} />
            </Switch>
        </AsistenciaMedicaContext.Provider>
    )
}

export default ProtectedAsistenciaMedicaWeb
