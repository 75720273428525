import { AxiosResponse } from "axios";
import { RestriccionPlan } from "../interfaces/restriccionPlan.interface";
import { BACKEND_ENDPOINT, PLAN, RESTRICCION } from "../utils/constants";

export const restriccionesFromBackend = (apiRequest: any, numeroPlan : string) => {
    return new Promise<RestriccionPlan[]>((resolve, reject) => {
        const url = `${BACKEND_ENDPOINT()}${PLAN}${numeroPlan}/${RESTRICCION}`;
        const method = 'GET';
        const options = {
            url,
            method
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data.restricciones))
            .catch((error : any) => reject(error));
    });
}