import React from 'react'
import './SplashScreen.scss'
import { IonPage } from '@ionic/react'

const SplashScreen = () => {
  return (
    <IonPage>
      <div className="loading-screen">
        <img className="loading-animation" src="/assets/logos/logo_osde.svg" alt="logo OSDE" style={{ width: '100px', height: 'auto' }} />
      </div>
    </IonPage>
  )
}

export default SplashScreen