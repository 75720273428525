import { Direccion } from "../interfaces/direccion.interface";
import { CABA, GOOGLE_MAPS_API_KEY, LIMITE_DISTANCIA } from "../utils/constants";
import { eliminarAcentos, localidadNormalizada } from "../utils/utils";

/**
 * Inserta el script necesario para llamar a la api de google con sus correspondientes librerias.
 */
const instanciarGoogleServices = () => {
    const src = "https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=" + GOOGLE_MAPS_API_KEY();
    const position = document.querySelector("head");
    const id = "asistencia-google-maps";

    if (!position) {
        return;
    }
    if (!document.querySelector("#" + id)) {
        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", id);
        script.src = src;

        // Agrega un evento onload para verificar cuándo se ha cargado la API.
        script.onload = function () {
            if (typeof google.maps.geometry === 'object') {
            // La biblioteca de "geometry" se ha cargado correctamente.
                console.info("La biblioteca de geometry se ha cargado.");
            } else {
                console.error("La biblioteca de geometry no se ha cargado correctamente.");
            }
        }

        position.appendChild(script);
    }
}

/**
 * Recibe como parametro la geolocalización para realizar un pedido a la api de google retornando una dirección con localidad
 * y barrio en el caso de ser necesario
 * @param geolocalizacion
 */
const direccionGeocode = (geolocalizacion) => {
    return new Promise<any>((resolve) => {
        const latlng = new google.maps.LatLng(geolocalizacion.lat, geolocalizacion.lng);
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'location': latlng }, function (results, status) {
            if (status === 'OK') {
                if (results.length > 0) {
                    const addressComponents = results[0].address_components;
                    let calle = '';
                    let altura = '';
                    let localidad = '';
                    let provincia = '';
                    let barrio = '';

                    for (let i = 0; i < addressComponents.length; i++) {
                        const component = addressComponents[i];
                        const types = component.types;

                        if (types.includes('administrative_area_level_1')) {
                            const province = component.short_name == "DF" ? CABA : component.long_name;
                            if(province === 'CABA') {
                                provincia = CABA;
                            } else {
                                const match = province.match(/Provincia de (.+)/);
                                provincia = match && match[1] ? match[1] : province;

                            }
                        } else if (types.includes('route')) {
                            calle = component.long_name;
                        } else if (types.includes('street_number')) {
                            altura = component.long_name;
                        } else if (types.includes('locality')) {
                            localidad = component.short_name === 'CABA' ? CABA : component.long_name;
                            if (localidad.length < 5 ) {
                                localidad  = ''; 
                                if(provincia == CABA)
                                    localidad = CABA
                            }
                        } else if(types.includes('sublocality')) {
                            barrio = component.long_name;
                        }
                    }

                    let localidadSinAcentos = eliminarAcentos(localidad);
                    let provinciaSinAcentos = eliminarAcentos(provincia);

                    localidadSinAcentos = localidadSinAcentos.toUpperCase();
                    const localidadFormateada = localidadNormalizada(localidadSinAcentos);

                    let direccion = {
                        calle,
                        altura,
                        localidad: localidadFormateada,
                        provincia: provinciaSinAcentos.toUpperCase(),
                        barrio: barrio.toUpperCase(),
                        latitud: geolocalizacion.lat,
                        longitud: geolocalizacion.lng
                    }
                    resolve(direccion);
                } else {
                    console.error('No se encontraron resultados de geocodificación.');
                }
            } else {
                console.error('Error en la solicitud de geocodificación: ' + status);
            }
        });
    })
}

/**
 * Se encarga de establecer la geolocalizacion para comprobar
 * que se encuentre cerca de alguna direccion cargada.
 */
const checkearGeolocalizacion = (direcciones, direccionSeleccionada, storageManager) => {
    return new Promise<any>((resolve, reject) => { 
        storageManager.getDataStorage("geolocalizacion").then(geo => {
            if(geo) {
            let geolocalizacion = JSON.parse(geo);

            let latitude = geolocalizacion.coords.latitude;
            let longitude = geolocalizacion.coords.longitude;
            let precision = geolocalizacion.coords.accuracy;

            const positionUser = new google.maps.LatLng(latitude, longitude);
            
            let menorDistancia = 0;
            let menorDireccion: Direccion;
            let menorBandera = true;
            
            direcciones.forEach((direccion: Direccion) => {
                if (direccion.posicionExacta) {
                    let positionDireccion = new google.maps.LatLng(direccion.latitud, direccion.longitud);
                        
                    let distancia = google.maps.geometry.spherical.computeDistanceBetween(
                        positionUser,
                        positionDireccion
                    );
                    if ((menorBandera || distancia < menorDistancia) && distancia < LIMITE_DISTANCIA) {
                        menorBandera = false;
                        menorDistancia = distancia;
                        menorDireccion = direccion;
                    }
                }
            });
            if (menorDireccion !== null && menorDireccion !== undefined && menorDireccion !== direccionSeleccionada) {
                if(menorDireccion.codigoPostal == direccionSeleccionada.codigoPostal &&
                    menorDireccion.altura == direccionSeleccionada.altura &&
                    menorDireccion.calle == direccionSeleccionada.calle
                ) {
                    resolve(null);
                } else {
                    resolve(menorDireccion);
                }
            } else {
                resolve(null);
            }
        } else {
            resolve(null);
        }
        }).catch(error => { 
            console.error("Geolocalizacion no disponible", error);
            reject(error);
        });
    })
}

export {instanciarGoogleServices, direccionGeocode, checkearGeolocalizacion}