import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { storageManager } from '../../helpers/dataProvider';
import { useRequest } from '../../hooks/webUseRequest';
import { setupData } from '../../utils/setupGA'
import { YsocialContext } from '../../contexts/YsocialContext';
import { ASISTENCIA_SOLICITADA_YSOCIAL, CANCELAR_SOLICITUD_YSOCIAL, DATOS_CONTACTOS_YSOCIAL, SELECCIONAR_DIRECCION_YSOCIAL, FORMULARIO_CONTACTO_YSOCIAL, FORMULARIO_DOMICILIO, RESUMEN_YSOCIAL, YSOCIAL_FEEDBACK, PARRAFO_SERVICIO_EN_CURSO, PARRAFO_SIN_GRUPO_FAMILIAR, ERROR_SERVICIO_EN_CURSO, TITULO_FEEDBACK, ASISTENCIA_ERROR_YSOCIAL, GRUPO_FAMILIAR_ERROR_YSOCIAL } from '../../utils/constants';
import DatosContactosYsocial from '../../pages/WebYsocial/DatosContactosYsocial/DatosContactosYsocial';
import { useYsocial } from '../../hooks/useYsocial';
import { SocioFamiliar } from '../../interfaces/socioFamiliar.interface';
import { Socio } from '../../models/socioPrincipal.model';
import { YsocialActionType } from '../../interfaces/global-state/ysocial.action.type.enum';
import FormularioContactoYsocial from '../../pages/WebYsocial/FormularioContactoYsocial/FormularioContactoYsocial';
import SeleccionarDomicilioYsocial from '../../pages/WebYsocial/SeleccionarDomicilioYsocial/SeleccionarDomicilioYsocial';
import ResumenYsocial from '../../pages/WebYsocial/ResumenYsocial/ResumenYsocial';
import AsistenciaSolicitadaYsocial from '../../pages/WebYsocial/AsistenciaSolicitadaYsocial/AsistenciaSolicitadaYsocial';
import CancelarYsocial from '../../pages/WebYsocial/CancelarYsocial/CancelarYsocial';
import FormularioDomicilioYsocial from '../../pages/WebYsocial/FormularioDomicilioYsocial/FormularioDomicilioYsocial';
import FeedbackYsocial from '../../pages/WebYsocial/FeedbackYsocial/FeedbackYsocial';
import { solicitarServiciosEnCurso } from '../../helpers/WebYsocial/serviciosEnCursoHelper';
import ErrorTecnicoYsocial from '../../pages/WebYsocial/ErrorTecnicoYsocial/ErrorTecnicoYsocial';
import SplashScreenYsocial from '../../pages/WebYsocial/SplashScreenYsocial/SplashScreenYsocial';
import { getServicioFromBackend } from '../../api/servicio';
import { restriccionesFromBackend } from '../../api/planService';
import TelefonosPageYsocial from '../../pages/WebYsocial/TelefonosPageYsocial/TelefonosPageYsocial';
import { Ysocial } from '../../interfaces/ysocial/ysocial.interface';
import { updateSocioPrincipal } from '../../api/socioPrincipalService';
import { cuilToDni } from '../../utils/ysocialUtils';
import { updateSocioFamiliar } from '../../api/socioFamiliarService';

const ProtectedYsocialRoutes = ({ authInfo, setSessionInfo }) => {

    const history = useHistory();
    const [error, setError] = useState(false);
    const [splashScreen, setSplashScreen] = useState(true);
    const [ysocialFeedback, setYsocialFeedback] = useState(false);
    const [validacionPlan, setValidacionPlan] = useState(false);
    const [parrafoFeedback, setParrafoFeedback] = useState("");
    const [message, setMessage] = useState("");
    const { apiRequest } = useRequest(history, setSessionInfo);
    const [homeYsocial, setHomeYsocial] = useState(false);
    const {
        ysocial,
        dispatchYsocial,
        errorAlInicializar
    } = useYsocial(authInfo, true, storageManager, useRequest);
    const socio = ysocial.usuario;
    const tituloFeedback = TITULO_FEEDBACK;
    
    useEffect(() => {
        if(errorAlInicializar) {
            setSplashScreen(false);
            setError(true);
        }
    }, [errorAlInicializar])
    

    useEffect(() => { 
        if (socio && !homeYsocial) {
            const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));
            const dni = ysocial.ysocialDni;

            const grupoFamiliarAux: (Socio | SocioFamiliar)[] = socio.familiares.length > 0 ? [...socio.familiares] : [];
            grupoFamiliarAux.unshift(socio);

            const usuarioFiltrado = grupoFamiliarAux.filter(familiar => cuilToDni(familiar.dni) === dni);

            if (usuarioFiltrado.length !== 0) {
                dispatchYsocial({
                    type: YsocialActionType.actualizarSocioSeleccionado,
                    socio: usuarioFiltrado[0]
                })

                validarCambioTelefono(usuarioFiltrado[0], ysocial);

                solicitarServicios(usuarioFiltrado[0]);

            } else {
                setParrafoFeedback(PARRAFO_SIN_GRUPO_FAMILIAR);
                setSplashScreen(false);
                setYsocialFeedback(true);
                setMessage(GRUPO_FAMILIAR_ERROR_YSOCIAL);
            }
        }

    }, [socio])

    const validarCambioTelefono = (socioSeleccionado:any, ysocialStorage : Ysocial) => {
        
        if(ysocialStorage.ysocialTelefono){

            if(socio.telefono !== ysocialStorage.ysocialTelefono){
                let telefono  = ysocialStorage.ysocialTelefono.toString();

                // Cortar los primeros 3 digitos que representan al pais
                if(telefono.length === 13){
                    telefono = telefono.slice(3);
                }

                handleUpdateSocio(socioSeleccionado,telefono);
            }
        }
    }

    const handleUpdateSocio = (socioSeleccionado:any, telefono: string) => {
        let socioAux = { ...socioSeleccionado, telefono: telefono}
    
        dispatchYsocial({
            type: YsocialActionType.actualizarSocioSeleccionado,
            socio: socioAux
        })
        try {
            socioAux.contratos ? updateSocioPrincipal(socioAux, apiRequest) : updateSocioFamiliar(socioAux, apiRequest);
        } catch (error) {
            console.error("Se produjo un error al actualizar los datos del socio", error);
        }
    }

    const solicitarServicios = async (socioSeleccionado) => {
        
        await solicitarServiciosEnCurso(socio, dispatchYsocial, storageManager, apiRequest).then(casosEnCurso => {
            const socioFiltrado = casosEnCurso.filter(caso => caso.nombre === socioSeleccionado?.nombre);
            
            const tieneServicioEnCurso = socioFiltrado.find(caso => caso.clasificaciones.some(clasificacion => (clasificacion.codigoSubTipoServicio === "CMO") || clasificacion.codigoSubTipoServicio === "VM"));
            
            if(tieneServicioEnCurso) {
                setParrafoFeedback(PARRAFO_SERVICIO_EN_CURSO);
                setMessage(ERROR_SERVICIO_EN_CURSO);
                setYsocialFeedback(true);

            } 
        }).catch(() => {
            setError(true);
            setSplashScreen(false)
        });
        
        await filtrarServiciosPorRestriccion();
    }

    const filtrarServiciosPorRestriccion = async () => {
        try {
            let serviciosHabilitados = await getServicioFromBackend(apiRequest)
            let serviciosHabilitadosFiltrados = []

            serviciosHabilitados.forEach((servicio) => {
                servicio.clasificacion.forEach((clasificacion) => {
                    if (clasificacion.disponibilidad.activa === true) {
                        serviciosHabilitadosFiltrados.push(clasificacion);
                    } 
                });
            })
            const restricciones = await restriccionesFromBackend(apiRequest, socio.plan);
            let serviciosSinRestricciones = serviciosHabilitadosFiltrados.filter((servicioHabilitadoFiltrado) => {
                return !restricciones.some((restriccion) => restriccion.clasificacion === servicioHabilitadoFiltrado.descripcion)
            }).filter((servicio) => servicio?.descripcion === 'CMO');

            dispatchYsocial({
                type: YsocialActionType.actualizarRestriccionesPlan,
                restricciones: serviciosSinRestricciones
            })

            if(restricciones?.length > 0 && restricciones[0]?.clasificacion === 'CMO') {
                setValidacionPlan(true);
                setSplashScreen(false);
            } else {
                setSplashScreen(false);
            }

        } catch(error) {
            console.error(`Ocurrio un error al obtener las restricciones: ${error}`);
            setError(true)
        }
    }

    if (splashScreen) {
        return <SplashScreenYsocial />
    }

    if(error) {
        return <ErrorTecnicoYsocial />
    }

    if(ysocialFeedback) {
        return <FeedbackYsocial titulo={tituloFeedback} parrafo={parrafoFeedback} message={message} />
    }

    if(validacionPlan) {
        return <TelefonosPageYsocial />
    }

    return (
        <YsocialContext.Provider value={{
            ysocial,
            dispatchYsocial,
            storageReady: true,
            storageManager,
            useRequest: () => ({ apiRequest: (options, retry) => apiRequest(options, retry) }),
            setupData,
            authInfo,
            isWeb: true,
            setHomeYsocial
        }}>
            <Switch>
				<Route exact path = {DATOS_CONTACTOS_YSOCIAL} component = {DatosContactosYsocial} />
				<Route exact path = {FORMULARIO_CONTACTO_YSOCIAL} component = {FormularioContactoYsocial}/>
                <Route exact path = {SELECCIONAR_DIRECCION_YSOCIAL} component = {SeleccionarDomicilioYsocial} />
                <Route exact path = {RESUMEN_YSOCIAL} component = {ResumenYsocial} />
                <Route exact path = {ASISTENCIA_SOLICITADA_YSOCIAL} component = {AsistenciaSolicitadaYsocial} />
                <Route exact path = {CANCELAR_SOLICITUD_YSOCIAL} component = {CancelarYsocial} />
                <Route exact path = {FORMULARIO_DOMICILIO} component = {FormularioDomicilioYsocial} />
                <Route exact path = {YSOCIAL_FEEDBACK} component = {FeedbackYsocial} />
			</Switch>
        </YsocialContext.Provider>
    )
}

export default ProtectedYsocialRoutes
