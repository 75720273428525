import React, { useEffect, useState } from 'react';
import { instanciarGoogleServices } from '../../helpers/googleApi';
import ProtectedYsocialRoutes from '../YsocialRoutes/ProtectedYsocialRoutes';
import ProtectedAsistenciaMedicaWeb from './ProtectedAsistenciaMedicaWeb';
import { sendTracks } from '../../helpers/GA';
import { useHistory } from 'react-router'

//historyTo deberia equivaler a la constante
const ProtectedRoutes = ({authInfo, setSessionInfo}) => {
    
    const { isYsocial } = JSON.parse(sessionStorage.getItem('ysocial'));
    
    const [page, setPage] = useState("")

    const history = useHistory()

    const setRouterListener = () => {
        if(page.length === 0){

            const configGA = {
                origin: 'router',
                page_location: 'asistenciamedica',
                page_referrer: ''
            }

            sendTracks(configGA)

            setPage('asistenciamedica')
        }

        history.listen((param)=> {

            const screen_name =  param.pathname.replace(/\//g, "_").replace("_", "")
            setPage((prev)=>{

                const configGA = {
                    origin: 'router',
                    page_location: screen_name,
                    page_referrer: prev
                }

                sendTracks(configGA)
                return screen_name
            } )

        } )
    }

    //TODO Utilizar el dato de storage
    
    useEffect(() => {
        instanciarGoogleServices();
        setRouterListener();
    }, [])

    return (
        isYsocial ? (
            <ProtectedYsocialRoutes authInfo={authInfo} setSessionInfo={setSessionInfo} />
        )
        :
        (
            <ProtectedAsistenciaMedicaWeb authInfo={authInfo} setSessionInfo={setSessionInfo} />
        )
    )
}

export default ProtectedRoutes
