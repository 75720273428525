import React, { useContext, useState } from 'react'
import { YsocialContext } from "../../../contexts/YsocialContext";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import "./AsistenciaSolicitadaYsocial.scss"
import { Feedback } from 'app-principal-componentes-visuales';
import { capitalizeString } from '../../../utils/utils';
import { ASISTENCIA_CREADA_YSOCIAL, PARRAFO_ERROR_GENERICO, TELEFONO_WHATSAPP, TITULO_FEEDBACK } from '../../../utils/constants';
import { saveYoizen } from '../../../api/mensajeriaService';
import { useRequest } from '../../../hooks/webUseRequest';
import { cerrarSesion } from '../../../helpers/WebYsocial/sesionHelper';

const AsistenciaSolicitadaYsocial = () => {

    const [waitingBtn, setWaitingBtn] = useState(false);
    const { ysocial } = useContext(YsocialContext);
    const { socioSeleccionado } = ysocial;
    const [isAsistenciaValida, setIsAsistenciaValida] = useState(true);

    const { apiRequest } = useRequest();
    const ysocialStorage = JSON.parse(sessionStorage.getItem("ysocial"));

    const handleYsocial = () => {
        setWaitingBtn(true);
        const message = ASISTENCIA_CREADA_YSOCIAL;
        saveYoizen(ysocialStorage.ysocialToken, message, 200, apiRequest)
            .then(async () => {
                await cerrarSesion();
            }).catch(error => {
                console.error("Error mensaje POST WhatsApp", error);
                setIsAsistenciaValida(false);
            });
    }

    return (
        <IonPage>
            <IonContent>
                <div className="ysocial-background-page">
                    <IonGrid className='ysocial_content-container ysocial-padding'>
                        <IonRow>
                            <IonCol size='12'>
                                {
                                    isAsistenciaValida
                                        ?
                                        <Feedback
                                            notOsde
                                            waiting={waitingBtn}
                                            asistencia
                                            handsPhoneReady
                                            labelButtonOk='Volver a WhatsApp'
                                            onClickOk={() => handleYsocial()}
                                            parrafo={`Tu solicitud fue gestionada. Para acceder al link de la consulta volve al WhatsApp. Al hacerlo se cerrará la sesión.`}
                                            titulo='Asistencia médica solicitada'
                                            id="feedback-asistencia-solicitada-ysocial"
                                        />
                                        :
                                        <Feedback
                                            notOsde
                                            waiting={waitingBtn}
                                            titulo={TITULO_FEEDBACK}
                                            parrafo={PARRAFO_ERROR_GENERICO}
                                            handsPhoneExclamation
                                            labelButtonOk={"Volver a WhatsApp"}
                                            onClickOk={() => cerrarSesion()}
                                            id="feedback-ysocial"
                                        />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default AsistenciaSolicitadaYsocial;