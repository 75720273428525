/** Components section */
import { IonApp } from '@ionic/react';
import { AsistenciaMedicaRouter } from './root';
import React, { useEffect, useState } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { AsistenciaMedicaWebRouter } from './root/router/AsistenciaMedicaWebRouter';
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./themes/variables.scss";
import { getPlatforms } from '@ionic/react';
import { Environment } from './root/environment/Environment';
import SplashScreen from './root/pages/SplashScreen/SplashScreen';

const App: React.FC = () => {

  const platforms = getPlatforms(); // returns ["iphone", "ios", "mobile", "mobileweb"] from an iPhone
  const isADS = platforms.includes('capacitor')
  //Inicializar en true el state de isInitialized
  // const [isInitialized, setIsInitialized] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    //Comentar el if para realizar pruebas en local
    if (!isADS) fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch("/env.json");
      const data = await response.json();
      Environment.ENV = data;
      setIsInitialized(true);
    } catch (error) {
      setIsInitialized(true);
      console.error("Error fetching configuration:", error);
    }
  };

  if (isADS) {
    return (
      <IonApp>
        <IonReactRouter>
          <AsistenciaMedicaRouter />
        </IonReactRouter>
      </IonApp>
    )
  }

  if (isInitialized) {
    return <AsistenciaMedicaWebRouter />
  } else {
    return <SplashScreen />
  }
}

export default App;
