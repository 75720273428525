import { AxiosResponse } from "axios";
import { DireccionNormalizadaMerlin } from "../interfaces/merlin/direccionNormalizada.interface";
import { Provincia } from "../interfaces/provincia.interface";
import { BACKEND_ENDPOINT, DIRECCION_PROVINCIA, NORMALIZADOR_ENDPOINT } from "../utils/constants";

export const getProvincias = (apiRequest: any) => {
    return new Promise<Provincia[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT() + DIRECCION_PROVINCIA;
        const method = 'GET';
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error: any) => reject(error));
    });
}

export const getDireccionNormalizada = (params: any, apiRequest: any) => {
    return new Promise<DireccionNormalizadaMerlin>((resolve, reject) => {
        const url = NORMALIZADOR_ENDPOINT();
        const method = 'GET';
        const options = {
            url,
            method,
            params
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}