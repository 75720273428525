import { IonContent, IonGrid, IonPage } from '@ionic/react'
import React from 'react'
import SessionHeader from '../Headers/SessionHeader/SessionHeader'
import "./PageWrapper.scss"
import Footer from '../Footer/Footer'

interface PageWrapperProps {
    children: React.ReactNode,
    pageClassName: string,
    socio: any,
    path: string,
    className?: string,
    notCancel?:boolean,
    notBack?:boolean,
    tituloHeader?: string,
    handlerHandleBack?: any,
    noPadding?: boolean
}

const PageWrapper = ({children, pageClassName, socio, path, className, notCancel, notBack, tituloHeader, handlerHandleBack, noPadding}: PageWrapperProps) => {
  return (
    <IonPage className={pageClassName} >
        <IonContent fullscreen>
          <IonGrid className='web_layout-container'>
            <SessionHeader titulo={tituloHeader} socioPrincipal={socio} path={path} notCancel={notCancel} notBack={notBack} handlerHandleBack={handlerHandleBack} />
            <IonGrid className={`${noPadding ? "" : "content__grid"} asistencia_content-container ${className ? className : ""}`}>
                {children}
            </IonGrid>
            <Footer />
          </IonGrid>
        </IonContent>
    </IonPage>
  )
}

export default PageWrapper