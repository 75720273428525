import { SocioFamiliar } from "../interfaces/socioFamiliar.interface";
import { YsocialGlobalState } from "../interfaces/global-state/ysocial.state.interface";
import { YsocialAction } from "../interfaces/global-state/ysocial.action.interface";
import { YsocialActionType } from "../interfaces/global-state/ysocial.action.type.enum";

export const ysocialReducer = (state: YsocialGlobalState, action: YsocialAction): YsocialGlobalState => {

    let esUsuario;
    if(state?.usuario && state?.socioSeleccionado){
        esUsuario = state.usuario.ic === state.socioSeleccionado.ic;
    }

    switch (action.type) {

        case YsocialActionType.actualizarUsuario:

            return {
                ...state,
                usuario: action.socio
            }

        case YsocialActionType.actualizarSocioSeleccionado:

            return {
                ...state,
                socioSeleccionado: action.socio
            }


        case YsocialActionType.actualizarDatosContactoSocioSeleccionado:
            if ( esUsuario ) {

                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        email: action.datosContacto.email,
                        telefono: action.datosContacto.telefono
                    },
                    usuario: {
                        ...state.usuario,
                        email: action.datosContacto.email,
                        telefono: action.datosContacto.telefono
                    }
                }

            } else {

                let indexFamiliarEditado = state.usuario.familiares.findIndex( familiar => familiar.ic === state.socioSeleccionado.ic );

                let familiarConDatosContactoEditados: SocioFamiliar = { ...state.usuario.familiares[indexFamiliarEditado] };
                familiarConDatosContactoEditados.email = action.datosContacto.email;
                familiarConDatosContactoEditados.telefono = action.datosContacto.telefono;

                let nuevosFamiliares: SocioFamiliar[] = [ ...state.usuario.familiares ];
                nuevosFamiliares.splice( indexFamiliarEditado , 1, familiarConDatosContactoEditados );

                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        email: action.datosContacto.email,
                        telefono: action.datosContacto.telefono
                    },
                    usuario: {
                        ...state.usuario,
                        familiares: nuevosFamiliares
                    }
                }
            }

        case YsocialActionType.actualizarDireccion:
            if(esUsuario) {
                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        direcciones: action.direcciones
                    },
                    usuario: {
                        ...state.usuario,
                        direcciones: action.direcciones
                    }
                }
            } else {
                return {
                    ...state,
                    usuario: {
                        ...state.usuario,
                        direcciones: action.direcciones
                    }
                }
            }
    
        case YsocialActionType.actualizarDireccionSeleccionada:
            return {
                ...state,
                direccionSeleccionada: action.direccionSeleccionada,
            }

        case YsocialActionType.restablecerTriage:
            return {
                ...state,
                datosParaTriage: undefined
            }

        case YsocialActionType.actualizarRestriccionesPlan:
            return {
                ...state,
                restriccionesPlan: action.restricciones
            }
            
        case YsocialActionType.actualizarCasosEnCurso:
            return {
                ...state,
                asistenciasSolicitadas: action.casosEnCurso
            }

        default: 

            throw new Error("No existe la operación especificada");

    }
}
