import { useReducer, useEffect, useState  } from "react";
import { formatearError } from "../utils/apiUtils";
import { ysocialReducer } from "../reducers/ysocialReducer";
import { ApiError } from "../interfaces/api.error.interface";
import { obtenerDatosSocio } from "../helpers/socioPrincipal";
import { AuthInfo } from "../interfaces/ads/auth.info.interface";
import { getYsocialInitialState } from "../helpers/getYsocialInitialState";
import { StorageManager } from "../interfaces/ads/storage.manager.interface";
import { HttpRequestOptions } from "../interfaces/ads/http.request.options.interface";
import { YsocialActionType } from "../interfaces/global-state/ysocial.action.type.enum";
import { obtenerCanalesStorage, obtenerFactoresStorage, obtenerProvinciasStorage, obtenerServicios, obtenerSintomasStorage } from "../helpers/storageHelper";

export const useYsocial = (
    user: AuthInfo,
    storageReady: boolean,
    storageManager: StorageManager | any,
    useRequest: () => { apiRequest: ( options: HttpRequestOptions ) => Promise<unknown> }
    ) => {

    const [ ysocial, dispatchYsocial ] = useReducer(
        ysocialReducer,
        getYsocialInitialState()
    );
    const [errorAlInicializar, setErrorAlInicializar] = useState<ApiError>(null);
    const { apiRequest } = useRequest();

    useEffect(() => {
        obtenerDatosSocio( Number(user.userInfo.interlocutorComercial), apiRequest, user )
            .then( socio => {
                dispatchYsocial({
                    type: YsocialActionType.actualizarUsuario, 
                    socio: socio
                });
            })
            .catch((error) => {
                setErrorAlInicializar(formatearError(error));
                sessionStorage.setItem("YSOCIAL_INIT_ERROR", JSON.stringify(errorAlInicializar));
                dispatchYsocial({
                    type: YsocialActionType.actualizarUsuario, 
                    socio: null
                });
            })
    }, [ user ]);

    useEffect(() => {
        if( storageReady ) {
            Promise.all([
                obtenerCanalesStorage( apiRequest, storageManager),
                obtenerProvinciasStorage( apiRequest, storageManager ),
                obtenerFactoresStorage( apiRequest, storageManager ),
                obtenerSintomasStorage( apiRequest, storageManager ),
                obtenerServicios( apiRequest, storageManager)
            ]).then( data => {
                console.info( "ASIS-MED - Ya se encuentran inicializados en Storage, los listados globales de Provincias, Factores y Sintomas.", data );
            }).catch( error => {
                console.error( "ASIS-MED - Ocurrio un error al inicializar los listados globales de Provincias, Factores y Sintomas.", error );
                setErrorAlInicializar(formatearError(error));
                sessionStorage.setItem("YSOCIAL_INIT_ERROR", JSON.stringify(errorAlInicializar));
            });
        };

    }, [ storageReady ]);
    
    return {
        ysocial,
        dispatchYsocial,
        errorAlInicializar
    }
}