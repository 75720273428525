import React, { useEffect, useState } from "react";
import { Feedback } from "app-principal-componentes-visuales";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { GRUPO_FAMILIAR_ERROR_YSOCIAL, PARRAFO_ERROR_GENERICO, TITULO_FEEDBACK } from "../../../utils/constants";
import { cerrarSesion } from "../../../helpers/WebYsocial/sesionHelper";
import { useRequest } from "../../../hooks/webUseRequest";
import { saveYoizen } from "../../../api/mensajeriaService";
import "./FeedbackYsocial.scss";

interface FeedbackYsocialProps {
    titulo: string,
    parrafo: string,
    message: string
}

const FeedbackYsocial = ({ titulo, parrafo, message }: FeedbackYsocialProps) => {

    const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));

    const { apiRequest } = useRequest();
    const [status, setStatus] = useState(0);
    const [waitingBtn, setWaitingBtn] = useState(false);
    const [yoizen, setYoizen] = useState(true);

    useEffect(() => {
        const statusError = (message === GRUPO_FAMILIAR_ERROR_YSOCIAL) ? 404 : 412;
        setStatus(statusError);
    }, [message])

    const cancelWithAnalytics = () => {
        setWaitingBtn(true);
        saveYoizen(ysocial.ysocialToken, message, status, apiRequest)
            .then(async () => {
                await cerrarSesion();
            }).catch(error => {
                console.error("Error mensaje POST WhatsApp", error);
                setYoizen(false);
            }).finally(() => {
                setWaitingBtn(false);
            }
            );
    }

    return (
        <IonPage>
            <IonContent>
                <div className="ysocial-background-page">
                    <IonGrid className="ysocial_content-container ysocial-padding">
                        <IonRow>
                            <IonCol size='12'>
                                {yoizen ?
                                    <Feedback
                                        notOsde
                                        waiting={waitingBtn}
                                        titulo={titulo}
                                        parrafo={parrafo}
                                        handsPhoneExclamation
                                        labelButtonOk={"Volver a WhatsApp"}
                                        onClickOk={cancelWithAnalytics}
                                        id="feedback-ysocial"
                                    />
                                    :
                                    <Feedback
                                        notOsde
                                        waiting={waitingBtn}
                                        titulo={TITULO_FEEDBACK}
                                        parrafo={PARRAFO_ERROR_GENERICO}
                                        handsPhoneExclamation
                                        labelButtonOk={"Volver a WhatsApp"}
                                        onClickOk={() => cerrarSesion()}
                                        id="feedback-ysocial"
                                    />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default FeedbackYsocial;