import React, { useState } from 'react'
import { IonGrid, IonRow, IonCol, IonContent, IonPage } from '@ionic/react';
import { Feedback } from 'app-principal-componentes-visuales';
import { useRequest } from '../../../hooks/webUseRequest';
import { saveYoizen } from '../../../api/mensajeriaService';
import { cerrarSesion } from '../../../helpers/WebYsocial/sesionHelper';
import { ASISTENCIA_ERROR_YSOCIAL, PARRAFO_ERROR_GENERICO, TITULO_FEEDBACK } from '../../../utils/constants';
import "./ErrorTecnicoYsocial.scss";

const ErrorTecnicoYsocial = () => {

    const { apiRequest } = useRequest();

    const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));
    const [waitingBtn, setWaitingBtn] = useState(false);
    const [yoizen, setYoizen] = useState(true);

    const cancelWithAnalytics = () => {
        setWaitingBtn(true);
        const message = ASISTENCIA_ERROR_YSOCIAL;
        saveYoizen(ysocial.ysocialToken, message, 500, apiRequest)
            .then(async () => {
                await cerrarSesion();
            }).catch(error => {
                console.error("Error mensaje POST WhatsApp", error);
                setYoizen(false);
            }).finally(() => {
                setWaitingBtn(false);
            });
    }

    return (
        <IonPage>
            <IonContent>
                <div className="ysocial-background-page">
                    <IonGrid className="ysocial_content-container ysocial-padding">
                        <IonRow>
                            <IonCol size='12'>
                                {
                                    yoizen ?
                                        <Feedback
                                            notOsde
                                            waiting={waitingBtn}
                                            id='problema-tecnico'
                                            // pathGA='problema-tecnico'
                                            titulo={"Tuvimos un problema técnico"}
                                            parrafo={"Estamos trabajando para resolverlo. Podés contactarnos a través de WhatsApp o llamada telefónica"}
                                            handsPhoneError
                                            labelButtonOk={"Volver a WhatsApp"}
                                            onClickOk={cancelWithAnalytics}
                                        />
                                        :
                                        <Feedback
                                            notOsde
                                            waiting={waitingBtn}
                                            titulo={TITULO_FEEDBACK}
                                            parrafo={PARRAFO_ERROR_GENERICO}
                                            handsPhoneExclamation
                                            labelButtonOk={"Volver a WhatsApp"}
                                            onClickOk={() => cerrarSesion()}
                                            id="feedback-ysocial"
                                        />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default ErrorTecnicoYsocial