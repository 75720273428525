import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorPage from "../../ErrorPage/ErrorPage";
import { useProximidad } from "../../../hooks/useProximidad";
import { YsocialContext } from "../../../contexts/YsocialContext";
import { inicioLocationWeb } from "../../../helpers/geolocalizacion";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { Button, Modal, Separador } from "app-principal-componentes-visuales";
import LocalidadError from "../../../components/Modals/LocalidadError/LocalidadError";
import ProximidadUbicacion from "../../../components/Modals/ProximidadUbicacion/ProximidadUbicacion";
import DatosYsocialComponent from "../../../components/WebYsocial/DatosYsocialComponent/DatosYsocialComponent";
import DireccionSeleccionadaComponent from "../../../components/WebYsocial/DireccionSeleccionadaComponent/DireccionSeleccionadaComponent";
import { DATOS_CONTACTOS_YSOCIAL, FORMULARIO_CONTACTO_YSOCIAL, FORMULARIO_DOMICILIO, RESUMEN_YSOCIAL, SELECCIONAR_DIRECCION_YSOCIAL } from "../../../utils/constants";
import "./DatosContactosYsocial.scss";

const DatosContactosYsocial = () => {

  const history = useHistory();
  const { ysocial, storageManager, setHomeYsocial } = useContext(YsocialContext);
  const { usuario } = ysocial;
  const [socio, setSocio] = useState(ysocial.socioSeleccionado);
  const [editarFormulario, setEditarFormulario] = useState(false);
  const [selectedDirection, setSelectedDirection] = useState(null);

  const { error, 
    localidadError, 
    proximidadModal, 
    direccionProxima,
    onClickContinuar, 
    handleClickCambiarDireccionProximidad,
    handleClickValidarProximidad, 
    setError, 
    setLocalidadError, 
    setProximidadModal } = useProximidad();

  useEffect(() => {
    setHomeYsocial(true);
    inicioLocationWeb(storageManager);
    foundSelected(usuario.direcciones);
  }, [ysocial]);

  /*
   * Encontrar entre todas las direcciones la que tiene atributo "seleccionado" igual a true
   */
  const foundSelected = (direcciones) => {
    const foundAddress = direcciones.find(
      (address) => address.seleccionado === true
    );
    foundAddress ? setSelectedDirection(foundAddress) : setSelectedDirection(null);
  };

  const handleEditarContacto = () => {
    history.push(FORMULARIO_CONTACTO_YSOCIAL, { socio })
  };

  const handleEditarUbicacion = () => {
    history.push(FORMULARIO_DOMICILIO, {
      selectedDirection: selectedDirection,
      showEliminar: true, 
      fromState: "datoscontactosysocial"          
    })
  }

  const handleCambiarUbicacion = () => {
    history.push(SELECCIONAR_DIRECCION_YSOCIAL);
  }

  const handleAgregarDatosContacto = () => {
    history.push(FORMULARIO_CONTACTO_YSOCIAL, { socio })
  }

  const handleAgregarNuevoDomicilio = () => { 
    history.push(FORMULARIO_DOMICILIO, {
      fromState: "datoscontactosysocial"
    })
  }

  if (error) {
    return <ErrorPage setError={setError} />
  }

  return (
    <>
      <PageWrapper
        socio={socio}
        pageClassName="datos-ysocial"
        path={DATOS_CONTACTOS_YSOCIAL}
        notCancel={false}
        notBack={true}
      >

        <DatosYsocialComponent socio={socio} handleEditarContacto={handleEditarContacto} handleAgregarDatosContacto={handleAgregarDatosContacto} />

        <Separador />

        <DireccionSeleccionadaComponent selectedDirection={selectedDirection} handleCambiarUbicacion={handleCambiarUbicacion} handleAgregarNuevoDomicilio={handleAgregarNuevoDomicilio} handleEditarUbicacion={handleEditarUbicacion} />

        <Button
          id="contacto-ysocial-continuar"
          primary
          label="Continuar"
          className="continuar__ysocial__button"
          onClick={() => onClickContinuar(selectedDirection)}
          disable={!socio.email || !socio.telefono ? true : false || !selectedDirection }
        />
      </PageWrapper>

      <Modal
        screen60
        className="modal_web"
        component={
          <ProximidadUbicacion
            onClickContinuar={() => onClickContinuar(selectedDirection)}
            onClickCambiarDireccion={handleClickCambiarDireccionProximidad}
            direccionMasProxima={direccionProxima}
          />
        }
        setShowModal={setProximidadModal}
        showModal={proximidadModal}
        id="modal-proximidad-ubicacion"
      />

      <Modal
        screen60
        hideCloseBtn
        //@ts-ignore
        component={
          <LocalidadError
            setLocalidadError={setLocalidadError}
            setEditarFormulario={setEditarFormulario}
          />
        }
        setShowModal={setLocalidadError}
        showModal={localidadError}
        id="modal-localidad-error"
      />
    </>
  );
};

export default DatosContactosYsocial;
