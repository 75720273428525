import { IonPage } from '@ionic/react'
import React from 'react'
import './SplashScreenYsocial.scss'

const SplashScreenYsocial = () => {
    return (
        <IonPage >
          <div className="loading-screen-ysocial">
            <img className="loading-animation-ysocial" src="/assets/logos/logo_osde_blue.svg" alt="logo OSDE" style={{ width: '100px', height: 'auto' }} />
          </div>
        </IonPage>
      )
}

export default SplashScreenYsocial