import { useContext } from "react";
// import { AsistenciaMedicaContext } from "../contexts/AsistenciaMedicaContext";
import { ContratoSalus } from "../interfaces/salus/contratosalus.interface";
import { CasoEnCurso } from "../interfaces/salus/servicioencurso.interface";
import { InfoCaso } from "../models/caso.model";
import { Socio } from "../models/socioPrincipal.model";
import { CLASIFICACIONES_CON_CODIGO_SEGURIDAD, EMERGENCIA, ESTADOS_ASIGNADOS, ESTADOS_CONFIRMADOS, PLANNED_SIGLA, PREFIJO_CELULARES, PREFIJO_CELULARES_LENGTH, PREFIJO_INTERNACIONAL_LENGTH, PREFIJO_INTERNACIONAL_TELEFONO, SERVICIOS_COMUNES, SIN_ACENTO } from "./constants";

// const { storageManager } = useContext(AsistenciaMedicaContext);
/**
 * Devuelve la edad del socio a partir de la fecha proporcionada.
 * @param fecha
 */
export const calcularEdad = (fecha: any) => {
    let hoy = new Date();
    let fechaAux = fecha.toString() + "T00:00:00";
    let fechaNacimiento = new Date(fechaAux);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
        edad--;
    }
    return edad;
}

/**
 * Obtiene el ultimo contrato de la lista de contratos del socio.
 * @param socio
 */
export function getUltimoContrato(socio: any) {
    let ultimoContrato: any;
    socio.contratos.forEach((contrato : ContratoSalus) => {
        if (Date.parse(contrato.fechaFinVigencia) > Date.now()) {
            if (!ultimoContrato) {
                ultimoContrato = contrato;
            } else if (
                Date.parse(contrato.fechaInicioVigencia) >
                Date.parse(ultimoContrato.fechaInicioVigencia) && 
                Date.parse(contrato.fechaFinContrato) > Date.now()
            ) {
                ultimoContrato = contrato;
            }
        }
    });
    return ultimoContrato;
}

/**
 * Devuelve el telefono sin prefijos adicionales
 */
export function formatearTelefono(telefono: string) {
    if (telefono) {
        telefono = telefono.replace(/\D/g, "");
        if (telefono.startsWith(PREFIJO_INTERNACIONAL_TELEFONO)) {
            telefono = telefono.slice(PREFIJO_INTERNACIONAL_LENGTH, telefono.length);
            if (telefono.startsWith(PREFIJO_CELULARES)) {
                telefono = telefono.slice(PREFIJO_CELULARES_LENGTH, telefono.length);
            }
        }
    }
    return telefono;
}

/**
 * Transforma las distintas formas de escritura de los parentescos
 * en una sola forma: sin Acentos y sin mayusculas.
 * @param contratoParentesco
 * @returns
 */
export function parentescoUniforme(contratoParentesco: any) {
    let parentesco = eliminarAcentos(contratoParentesco);
    if (parentesco) {
        parentesco = parentesco.toLocaleLowerCase();
    }
    return parentesco;
}

/**
 * Funcion que permite eliminar los acentos dado un texto. No elimina las Ñ/ñ.
 * @param texto
 * @returns
 */
export function eliminarAcentos(texto: string) {
    if (texto != null) {
        texto = texto.normalize("NFD");
        texto = texto.replace(SIN_ACENTO, "$1");
    }
    return texto;
}

/**
 * Funcion que permite reemplazar la ñ minúscula a mayúscula y eliminar caracter indeseado que retorna la api de google.
 * @param localidad
 * @return localidad con el formato necesario para utilizar en el formulario de direcciones
 */
export const localidadNormalizada = (localidad: String) => {
    const codigoUnicodeCorrecto = 209; // Valor correcto para la 'Ñ' mayúscula
    let localidadCorregida = localidad.replace(String.fromCharCode(771), String.fromCharCode(codigoUnicodeCorrecto));
    localidadCorregida = localidadCorregida.includes(String.fromCharCode(209)) ? localidadCorregida.replace(String.fromCharCode(78), ''): localidadCorregida;
    return localidadCorregida
}

/** Metodo para formatear el numero en el formato XXXX.XXX.XXXX
 *  @param numeroDeTelefono
 *  @return numero con el formato indicado anteriormente.
*/
export const formatearNumeroBoton = (numeroDeTelefono: any) => {
    let numeroFormateado;
    if (!numeroDeTelefono || isNaN(numeroDeTelefono)) numeroFormateado = `numero invalido ${numeroDeTelefono}`;
    if (typeof (numeroDeTelefono) !== 'string') numeroDeTelefono = numeroDeTelefono.toString();
    if (numeroDeTelefono.length === 11) {
        numeroFormateado = numeroDeTelefono.replace(/(\d{4})(\d{3})(\d{4})/, "$1.$2.$3");
    } else if (numeroDeTelefono.length < 11) {
        numeroFormateado =  'numero inferior a 11';
    } else if (numeroDeTelefono.length > 11) {
        numeroFormateado = 'numero mayor a 11';
    } else {
        numeroFormateado = 'error inesperado';
    }
    return numeroFormateado;
}

/**
 * Metodo encargado de capitalizar un string
 * @param text
 */
export const capitalizeString = (text: string) => {
    if (text.includes(" ")) {
        const arrayWord = text.split(" ");
        return (arrayWord.map((word: string) => { return capitalizeWord(word) })).join(" ");
    } else {
        if (text.length === 1) { return text.toUpperCase() }
        return capitalizeWord(text)
    }
};

export const capitalizeWord = (word: string) => {
    return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
};

/** Metodo para formatear el numero en el formato XX · XXXX · XXXX
 *  @param numeroDeTelefono
 *  @return numero con el formato indicado anteriormente.
*/
export const formatearNumeroInputCelular = (numeroDeTelefono: any) => {
    let numeroFormateado;
    if (!numeroDeTelefono || isNaN(numeroDeTelefono)) numeroFormateado = `numero invalido ${numeroDeTelefono}`;
    if (typeof (numeroDeTelefono) !== 'string') numeroDeTelefono = numeroDeTelefono.toString();
    if (numeroDeTelefono.length === 10) {
        numeroFormateado = numeroDeTelefono.replace(/(\d{2})(\d{4})(\d{4})/, "$1 · $2 · $3");
    } else if (numeroDeTelefono.length < 10) {
        numeroFormateado = 'numero inferior a 10';
    } else if (numeroDeTelefono.length > 10) {
        numeroFormateado = 'numero mayor a 10';
    } else {
        numeroFormateado = 'error inesperado';
    }
    return numeroFormateado;
}

/**
 * Devuelve un string con la lista de casos seleccionados por el usuario.
 */
export function listarSintomas(sintomas) {
    let listadoSintomas: string = "";
    for (let sintoma of sintomas) {
        listadoSintomas = listadoSintomas.concat(sintoma.descripcion).concat(", ");
    }
    listadoSintomas = listadoSintomas.slice(0, -2).concat(".");

    return listadoSintomas;
}

export const textIsValid = (text) => {
    const FORBIDDEN_CHAR_REGEX = /[^\u0000-\u00ff]/u;
    const notValid = FORBIDDEN_CHAR_REGEX.test(text)
    return !notValid
}

export const convertirInfoCaso = (casos) => {
    return new Promise<any>((resolve) => {
        let result = []
        casos.forEach((caso) => {
            const infoCaso = {
                principal: {
                    nroContrato: caso.numeroSocio,
                    nombre: caso.nombreSocio,
                    apellidos: caso.apellidosSocio
                },   
                fechaCreacion: caso.horaCreacion,   
                codigoSubTipoServicio: caso.codigoSubTipoServicio,
                codigoTipoServicio: caso.codigoTipoServicio,
                estado: caso.codigoUltimoEstatus,
                canalEntrada: caso.codigoCanalEntrada,
                excepcionCoseguro: caso.excepcionCoseguro,
                prestador: caso.prestador,
                coseguro: caso.coseguro,
            };
            result.push(infoCaso);
        });
        resolve(result);
    });
}

export const cargarInfoCasoSegunEstado = (casoSalus: CasoEnCurso, storageManager) => {
    return new Promise<any>(async (resolve) => {
        if (SERVICIOS_COMUNES.includes(casoSalus.codigoTipoServicio)) {
            let casoEnCursoInfoCargado = await cargarCasosComunes(casoSalus, storageManager);
            if (casoEnCursoInfoCargado.descripcion) {
                casoEnCursoInfoCargado.estado = PLANNED_SIGLA;
                casoEnCursoInfoCargado.estadoSalus = casoSalus.codigoUltimoEstatus;
                if (ESTADOS_ASIGNADOS.includes(casoSalus.codigoUltimoEstatus)) {
                //En la proxima fase se agregara el canal delivery en esta seccion
                } else if (ESTADOS_CONFIRMADOS.includes(casoSalus.codigoUltimoEstatus)) {
                    //En la proxima fase se agregara el canal delivery en esta seccion
                    if (CLASIFICACIONES_CON_CODIGO_SEGURIDAD.includes(casoSalus.codigoSubTipoServicio)) {
                        casoEnCursoInfoCargado.codigoSeguridad = casoSalus.codigoMovil;
                    }
                    if (!casoSalus.codigoPrestador.includes("PP") && !casoSalus.codigoPrestador.includes("URG")) {
                        casoEnCursoInfoCargado.prestador = casoSalus.prestador;
                    }
                }
                resolve(casoEnCursoInfoCargado);
            } else {
                resolve({})
            }
        } else if (
            casoSalus.codigoTipoServicio === EMERGENCIA && ESTADOS_CONFIRMADOS.includes(casoSalus.codigoUltimoEstatus)) {
            let casoEnCursoInfoCargado = await cargarCasosComunes(casoSalus, storageManager);
            casoEnCursoInfoCargado.estado = PLANNED_SIGLA;
            casoEnCursoInfoCargado.estadoSalus = casoSalus.codigoUltimoEstatus;
            if (casoEnCursoInfoCargado.descripcion) {
            //En la proxima fase se agregara el canal delivery en esta seccion
                if (!casoSalus.codigoPrestador.includes("PP") && !casoSalus.codigoPrestador.includes("URG")) {
                    casoEnCursoInfoCargado.prestador = casoSalus.prestador;
                }

                resolve(casoEnCursoInfoCargado);
            } else { 
                resolve({})
            }

        } else {
            resolve({});
        }
    });
}

export const cargarCasosComunes = (caso, storageManager) => {
    return new Promise<any>((resolve) => {
        let casoEnCursoInfo = new InfoCaso();
        storageManager.getDataStorage("clasificaciones").then(clasificaciones => {
            storageManager.getDataStorage("servicios").then(servicios => {
                storageManager.getDataStorage("canales").then(canales => {
                    let clasificacionesParse = JSON.parse(clasificaciones);
                    let serviciosParse = JSON.parse(servicios);
                    let canalesParse = JSON.parse(canales);
                    
                    let clasificacionHabilitada;
                    let servicioHabilitado = serviciosParse.find((servicio) => {
                        if (servicio.descripcion === caso.codigoTipoServicio && servicio.visibleEnCurso) {
                            return true;
                        }
                    });
                    if (servicioHabilitado) {
                        clasificacionHabilitada = clasificacionesParse.find((clasificacion) => {
                            if (clasificacion.descripcion === caso.codigoSubTipoServicio &&
                                clasificacion.visibleEnCurso && clasificacion.servicio.descripcion === caso.codigoTipoServicio) {
                                casoEnCursoInfo.codigoSubTipoServicio = clasificacion.descripcion;
                                casoEnCursoInfo.codigoTipoServicio = clasificacion.servicio.descripcion;
                            if (!caso.codigoUltimoEstatus) {
                                casoEnCursoInfo.descripcion = "Asistencia Médica de Urgencias";
                            } else {
                                casoEnCursoInfo.icono = clasificacion.clasificacionDetalle.iconoListado;
                                casoEnCursoInfo.descripcion = clasificacion.clasificacionDetalle.detalleListado;
                            }
                                return true;
                            }
                        });
                    }

                    if (clasificacionHabilitada) {
                        casoEnCursoInfo.salus = true;
                        casoEnCursoInfo.principal = new Socio();
                        casoEnCursoInfo.principal.nombre = caso.nombreSocio;
                        casoEnCursoInfo.principal.apellidos = caso.apellidosSocio;
                        casoEnCursoInfo.principal.nroContrato = caso.numeroSocio;

                        let canalEntrada = canalesParse.find((canal) => {
                            if (canal.codigo === caso.codigoCanalEntrada) {
                                return canal;
                            }
                        });
                        casoEnCursoInfo.canalEntrada = canalEntrada.descripcion;
                        casoEnCursoInfo.fechaCreacion = new Date(caso.horaCreacion);

                        casoEnCursoInfo.coseguro = caso.coseguro;
                        casoEnCursoInfo.excepcionCoseguro = caso.excepcionCoseguro;

                        casoEnCursoInfo.nroCaso = caso.codigo;
                    }
                    resolve(casoEnCursoInfo);
                });
            });
        });
    });
}

export const obtenerPreguntas = (sintoma, preguntas) => {
    let arrayPreguntas= [];
    sintoma.preguntas.forEach((pregunta, index) => {
        let preguntaAux={ ...pregunta, respuesta: preguntas[index] }
        arrayPreguntas.push(preguntaAux)
    });
    return arrayPreguntas;
}