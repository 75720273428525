/** Enumerable que lista los tipos de acciones soportadas por el dispatcher (dispatchAsistenciaMedica)
 * que permite modificar el estado global del modulo (asistenciaMedica).
 */
export enum YsocialActionType {

    /** Permite reemplazar la información completa del usuario.
     * 
     *  Requiere que se envie el Socio con los nuevos datos dentro del atributo socio
     */
    actualizarUsuario = 'ACTUALIZAR_USUARIO',

    /**
     * Informa el Socio sobre el cual desea operar (consultar o modificar datos) el usuario autenticado.
     * 
     * Requiere que se envie el Socio seleccionado por el usuario dentro del atributo socio.
     */
    actualizarSocioSeleccionado = 'SELECCIONAR_SOCIO',

    /**
     * Actualiza los datos de contacto del socio actualmente seleccionado.
     * 
     * Requiere que se envie un objeto conteniendo la nueva información, dentro del atributo datosContacto.
     */
    actualizarDatosContactoSocioSeleccionado = 'ACTUALIZAR_DATOS_CONTACTO_SOCIO_SELECCIONADO',

    /**
     * Actualiza una dirección del listado de direcciones del usuario.
     * 
     * Requiere que se envie la Direccion con sus nuevos datos, dentro del atributo direccion.
     */
    actualizarDireccion = 'ACTUALIZAR_DIRECCION',

    /**
     * Actualiza direccion seleccionada en el flujo de solicitar una asistencia.
     * 
     * Requiere que se envie la Direccion que se desea actualizar, dentro del atributo direccion.
     */
    actualizarDireccionSeleccionada = 'ACTUALIZAR_DIRECCION_SELECCIONADA',

    /**
     * Restablece el estado de los sintomas, preguntas y observaciones a su estado inicial.
     * 
     * No requiere de envio de atributos adicionales.
     */
    restablecerTriage = 'RESTABLECER_TRIAGE',
    /**
     * Actualiza el estado de las restricciones luego de su correspondiente peticion al servicio.
     * 
     * Requiere que se envie el listado de restricciones, dentro del atributo restricciones
     */
    actualizarRestriccionesPlan = 'ACTUALIZAR_RESTRICCIONES_PLAN',

    /**
     * Actualiza el estado de los casos en curso luego de su correspondiente peticion al servicio.
     * 
     * Requiere que se envie los casos en curso, dentro del atributo asistenciasSolicitadas.
     */
    actualizarCasosEnCurso = 'ACTUALIZAR_CASOS',
}