import React from 'react'
import { IonRow, IonCol } from '@ionic/react'
import { capitalizeString } from '../../../utils/utils'
import { Texto1Linea, TextLink, Subtitulo } from 'app-principal-componentes-visuales'
import { Direccion } from '../../../interfaces/direccion.interface'
import "./DireccionSeleccionadaComponent.scss"

interface DireccionSeleccionadaComponentProps {
    selectedDirection: Direccion,
    handleCambiarUbicacion: () => void,
    handleAgregarNuevoDomicilio: () => void,
    handleEditarUbicacion: () => void,
}

const DireccionSeleccionadaComponent = ({ selectedDirection, handleCambiarUbicacion, handleAgregarNuevoDomicilio, handleEditarUbicacion }: DireccionSeleccionadaComponentProps) => {
    return (
        <div className='direccion__seleccionada__component'>
            <IonRow>
                <IonCol size="12">
                    <Subtitulo
                        titulo="Datos de ubicación"
                        txt16
                        id="subtitulo-ubicacion-ysocial"
                    />
                    <Subtitulo
                        txt14
                        titulo={
                            "Debe corresponder al lugar donde se encontrará para recibir la asistencia médica."
                        }
                        id="subtitulo-seleccionar-domicilio"
                    />
                </IonCol>
            </IonRow>

            { selectedDirection ? (
                <>
                    <IonRow>
                        <IonCol size="12" className="contenido-inputs">
                            <Texto1Linea
                                colorBlack
                                left
                                texto={
                                    selectedDirection.barrio
                                        ? capitalizeString(
                                            `${selectedDirection.calle} ${selectedDirection.altura}, ${selectedDirection.barrio}`
                                        )
                                        : capitalizeString(
                                            `${selectedDirection.calle} ${selectedDirection.altura}`
                                        )
                                }
                                txt16
                            />
                            <Texto1Linea
                                colorGray
                                id="texto"
                                left
                                texto={
                                    selectedDirection.provincia == "CAPITAL FEDERAL"
                                        ? "Ciudad Autónoma de Buenos Aires"
                                        : capitalizeString(selectedDirection.provincia)
                                }
                            />
                            <Texto1Linea
                                colorGray
                                id="texto"
                                left
                                texto={
                                    selectedDirection.observaciones
                                        ? capitalizeString(selectedDirection.observaciones)
                                        : "No posee observaciones"
                                }
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='row--margin'>
                        <IonCol>
                            <TextLink
                                id="editar-contacto-ysocial"
                                onClick={handleEditarUbicacion}
                                left
                                texto="Editar datos"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <TextLink
                                id="cambiar-ubicacion-ysocial"
                                onClick={handleCambiarUbicacion}
                                right
                                texto="Seleccionar otro domicilio"
                            />
                        </IonCol>
                    </IonRow>
                </>
            ) : (
                <>
                    <IonRow className="row-no-datos">
                        <Subtitulo
                            txt16
                            className="domicilios_card"
                            txtCenter
                            titulo={"No tenés domicilios registrados"}
                            id="subtitulo-domicilio"
                        />
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <TextLink
                                id="cambiar-ubicacion-ysocial"
                                onClick={handleAgregarNuevoDomicilio}
                                right
                                texto="Agregar nuevo domicilio"
                            />
                        </IonCol>
                    </IonRow>
                </>
            )}
        </div>
    )
}

export default DireccionSeleccionadaComponent