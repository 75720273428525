import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { get, set } from '../../helpers/webStorageHelper';
import axios from 'axios';
import jwt_decode from 'jwt-decode'
import pkceChallenge from "pkce-challenge";
import { Environment } from '../../environment/Environment';
import { DATOS_CONTACTOS_YSOCIAL, loginHintDefault } from '../../utils/constants';
import SplashScreen from '../../pages/SplashScreen/SplashScreen';
import validatedCodesJSON from '../../mocks/validatedCodes.json'
import { Ysocial } from '../../interfaces/ysocial/ysocial.interface';

const LoginRoutes = ({ setSessionInfo, history }) => {

  const location = useLocation();

  useEffect(() => {

    const authFlow = async () => {

      //COMENTAR FLUJO PKCE PARA QUE FUNCIONE AL LEVANTAR LOCAL LINEAS 23 A 52
      const LOGIN_URI = Environment.ENV.LOGIN_URI;

      let storage_code_verifier = sessionStorage.getItem('codeVerifier')
      let code_challenge_storage = sessionStorage.getItem('codeChallenge')

      const codeChallengeMethod = `&code_challenge_method=S256`;
      const codeChallengeString = `&code_challenge=${code_challenge_storage}`;

      let authUrl = `${LOGIN_URI}${codeChallengeMethod}${codeChallengeString}${loginHintDefault}`

      if (!storage_code_verifier) {

        const { code_verifier, code_challenge } = pkceChallenge();
        storage_code_verifier = code_verifier
        code_challenge_storage = code_challenge

        sessionStorage.setItem('codeVerifier', storage_code_verifier)
        sessionStorage.setItem('codeChallenge', code_challenge_storage)

        const codeChallengeMethod = `&code_challenge_method=S256`;
        const codeChallengeString = `&code_challenge=${code_challenge_storage}`;

        //escucha cambio de parametros en la url
        const searchParams = new URLSearchParams(location.search);
        const ysocialStorage = JSON.parse(sessionStorage.getItem('ysocial'));

        setearYsocialStorage(ysocialStorage, searchParams);

        authUrl = `${LOGIN_URI}${codeChallengeMethod}${codeChallengeString}${loginHintDefault}`
        window.location.replace(authUrl);
        return;
      }

      //valida si hay auth info en storage
      const authInfoJSON = await get('validatedCodes')
      const authInfo = JSON.parse(authInfoJSON)

      //DESCOMENTAR LINEAS PARA LEVANTAR LOCAL TESTING
      //set("validatedCodes", JSON.stringify(validatedCodesJSON))
      //const authInfo = validatedCodesJSON

      //escucha cambio de parametros en la url
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code')
      //const grantId = searchParams.get('grant_id')
      const ysocialStorage = JSON.parse(sessionStorage.getItem('ysocial'));

      setearYsocialStorage(ysocialStorage, searchParams);

      if (!authInfo) {
        if (!code) {
          window.location.replace(authUrl);
          //window.location.replace(LOGIN_URI);
        } else {
          const authorizationEndpoint = `${Environment.ENV.AUTHORIZATION}/${code}/code-verifier/${storage_code_verifier}`
          //const authorizationEndpoint = `${Environment.ENV.AUTHORIZATION}/${code}`
          axios.get(authorizationEndpoint)
            .then((resp) => {

              let ExpiresInToken = +resp.data.expires_in;
              let ExpiraDate = new Date();
              ExpiraDate.setSeconds(ExpiresInToken);

              const sessionExpiresDate = new Date(ExpiraDate)
              const refreshToken = resp.data.refresh_token
              const accessToken = resp.data.access_token
              const idToken = resp.data.id_token
              const userInfo = jwt_decode(idToken);

              sessionStorage.setItem("expires_in", sessionExpiresDate.toString());
              sessionStorage.setItem("refresh_token", refreshToken);
              sessionStorage.setItem("id_token", idToken);
              sessionStorage.setItem("access_token", accessToken);
              sessionStorage.setItem("platform", 'web');

              const bodyRequest = {
                userInfo,
                refreshToken,
                accessToken,
                idToken,
                expirationTime: ExpiraDate
              }

              set("validatedCodes", JSON.stringify(bodyRequest));

              setSessionInfo(bodyRequest)
              //SI ES YSOCIAL TRUE NAVEGAR A LA NUEVA RUTA DONDE ESTE LA LOGICA DE LOS LLAMADOS

              const { isYsocial } = JSON.parse(sessionStorage.getItem('ysocial'));
              isYsocial ? history.push(DATOS_CONTACTOS_YSOCIAL) : history.push('/asistenciamedica');
            })
            .catch((error) => {
              console.error(error)
              window.location.replace(authUrl);
            })
        }

      } else {
        setSessionInfo(authInfo)
        const { isYsocial } = JSON.parse(sessionStorage.getItem('ysocial'));
        isYsocial ? history.push(DATOS_CONTACTOS_YSOCIAL) : history.push('/asistenciamedica');
      }

    }

    authFlow()

  }, [])

  const setearYsocialStorage = (ysocialStorage, searchParams) => {
    if (!ysocialStorage || !ysocialStorage.isYsocial) {

      const ysocialToken = searchParams.get('token')
      const codeYsocial = searchParams.get('ysocial')

      if (codeYsocial === 'true') {
        const ysocialDni = searchParams.get('documento')
        const ysocialSintoma = decodeURIComponent(searchParams.get('sintomas'))
        const ysocialAntecedentes = searchParams.get('antecedente')
        const ysocialPreguntas = searchParams.get('preguntas')
        const ysocialTelefono = searchParams.get('telefono')
        const ysocial: Ysocial = {
          isYsocial: true,
          ysocialToken,
          ysocialDni,
          ysocialPreguntas,
          ysocialSintoma,
          ysocialAntecedentes,
          ysocialTelefono
        }
        sessionStorage.setItem("ysocial", JSON.stringify(ysocial));
      } else {
        const ysocial = {
          isYsocial: null,
          ysocialToken: null
        }
        sessionStorage.setItem("ysocial", JSON.stringify(ysocial));
      }
    }
  }

  return (
    <>
      {/* <Route  path={"/login"} component={ SplashScreen } />
      <Route path={"/"} component={SplashScreen} /> */}
    </>
  )
}

export default LoginRoutes