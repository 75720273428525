import React from 'react'
import { IonRow, IonCol } from '@ionic/react'
import { Socio } from '../../../models/socioPrincipal.model'
import { capitalizeString, formatearNumeroInputCelular } from '../../../utils/utils'
import { SocioFamiliar } from '../../../interfaces/socioFamiliar.interface'
import { Texto1Linea, TextLink, Subtitulo, Titulo } from 'app-principal-componentes-visuales'

interface DatosYsocialComponentProps {
    socio: Socio | SocioFamiliar,
    handleEditarContacto: () => void,
    handleAgregarDatosContacto: () => void
}

const DatosYsocialComponent = ({ socio, handleEditarContacto, handleAgregarDatosContacto }: DatosYsocialComponentProps) => {

    return (
        <>
            <IonRow>
                <IonCol size="12">
                    <Titulo
                        titulo={`Completa los datos para esta asistencia ${capitalizeString(
                            `${socio.apellidos} ${socio.nombre}`
                        )}`}
                        id="titulo-asistencia-ysocial"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <Subtitulo
                        titulo="Datos de contacto"
                        txt16
                        id="subtitulo-contacto-ysocial"
                    />
                    <Subtitulo
                        titulo="Nos permitirán contactarte en caso de que sea necesario para resolver esta solicitud."
                        txt14
                        id="subtitulo-solicitud"
                    />
                </IonCol>
            </IonRow>
            
            {socio.telefono ? (
                <>
                    <IonRow>
                        <IonCol size="12" className="contenido-inputs">
                            <Texto1Linea
                                colorBlack
                                left
                                texto={
                                    !socio.telefono
                                        ? " - "
                                        : formatearNumeroInputCelular(socio.telefono)
                                }
                                txt16
                            />
                            <Texto1Linea
                                colorBlack
                                left
                                texto={!socio.email ? " - " : socio.email}
                                txt16
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <TextLink
                                id="editar-contacto-ysocial"
                                onClick={handleEditarContacto}
                                left
                                texto="Editar datos"
                            />
                        </IonCol>
                    </IonRow>
                </>
            ) : (
                <>
                    <IonRow className="row-no-datos">
                        <Subtitulo
                            txt16
                            className="domicilios_card"
                            txtCenter
                            titulo={"No tenés datos de contacto registrados"}
                            id="subtitulo-domicilio"
                        />
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <TextLink
                                id="cambiar-ubicacion-ysocial"
                                onClick={handleAgregarDatosContacto}
                                right
                                texto="Agregar datos de contacto"
                            />
                        </IonCol>
                    </IonRow>
                </>
            )}
        </>
    )
}

export default DatosYsocialComponent