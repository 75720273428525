import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow,  } from "@ionic/react"
import { Modal, Subtitulo, Titulo, ButtonAsistencia} from "app-principal-componentes-visuales"
import React, { useContext, useState, useEffect } from "react";
import Contactanos from "../../components/Modals/Contactanos/Contactanos";
import { AsistenciaMedicaContext } from "../../contexts/AsistenciaMedicaContext";
import { AsistenciaMedicaActionType } from "../../interfaces/global-state/asistencia.medica.action.type.enum";
import { ESTADOS_ASIGNADOS, ESTADOS_CONFIRMADOS, FACTORES_RIESGO, SERVICIO_EN_CURSO, TITULO_INFORMACION_IMPORTANTE, SELECCIONAR_SOCIO, TELEFONOS_PAGE, HOME } from "../../utils/constants";
import { capitalizeString } from "../../utils/utils";
import './SeleccionarSocio.scss';
import './SeleccionarSocio.scss';
import { restriccionesFromBackend } from "../../api/planService";
import { getServicioFromBackend } from "../../api/servicio";
import AntecedentesSkeleton from "../../components/Skeletons/AntecedentesSkeleton/AntecedentesSkeleton";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import './SeleccionarSocio.scss';
import { armarMensajeEsMenor } from "../../utils/whatsappUtils";
import { whatsappEsMenor } from "../../helpers/whatsappSalienteHelper";

const SeleccionarSocio = ({ history }) => {

    const { asistenciaMedica, dispatchAsistenciaMedica, setupData, useRequest, storageManager } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const [ showContactanosModal, setShowContactanosModal ] = useState(false);
    const [estadoSocioPrincipal, setEstadoSocioPrincipal] = useState({estadoSalus: 'CE'});
    const [loading, setLoading] = useState(false);
    const [modalEsMenor, setModalEsMenor] = useState(false);
    const [carritoAbandonadoDisable, setCarritoAbandonadoDisable] = useState(false);
    const socio = asistenciaMedica.usuario;
    const [mensaje, setMensaje] = useState('');
    const [sociosNoDuplicados, setSociosNoDuplicados] = useState(socio.familiares);
    const planSocio = socio.plan;
    const controlVerdes = asistenciaMedica.controlVerdes;

    useEffect(() => {
        
        const personasUnicas = sociosNoDuplicados.filter((persona, index, self) =>
            index === self.findIndex((p) => p.dni === persona.dni)
        );
        setSociosNoDuplicados(personasUnicas);
        const asistenciaSocioPrincipal = asistenciaMedica.asistenciasSolicitadas.find( asis => asis.principal.nombre.toUpperCase() === socio.nombre.toUpperCase() )

        if(asistenciaSocioPrincipal) {
            if(controlVerdes?.length > 0 ) {
                const filtrarControlVerdes = controlVerdes.filter((socio) => socio.nombre.toUpperCase() === asistenciaSocioPrincipal.principal.nombre.toUpperCase());
                const tieneServicios = filtrarControlVerdes[0]?.controlVerdes.length > 0 ? true : false;

                if (!tieneServicios) {
                    setEstadoSocioPrincipal(asistenciaSocioPrincipal);
                }
            }
        }

        storageManager.getDataStorage("deshabilitacion").then(deshabilitarModulos => {
            let deshabilitarParse = JSON.parse(deshabilitarModulos);
            setCarritoAbandonadoDisable(deshabilitarParse?.deshabilitarMenor);
        })
    }, [socio])

    const filtrarServiciosPorRestriccion = async () => {
        try{
            let serviciosHabilitados = await getServicioFromBackend(apiRequest)
            let serviciosHabilitadosFiltrados = []

            serviciosHabilitados.forEach((servicio) => {
                servicio.clasificacion.forEach((clasificacion) => {
                    if (clasificacion.disponibilidad.activa === true) {
                        serviciosHabilitadosFiltrados.push(clasificacion);
                    } 
                });
                
            })
            const restricciones = await restriccionesFromBackend(apiRequest, planSocio)
            let serviciosSinRestricciones = serviciosHabilitadosFiltrados.filter((servicioHabilitadoFiltrado) => {
                return !restricciones.some((restriccion) => restriccion.clasificacion === servicioHabilitadoFiltrado.descripcion)
            })

            dispatchAsistenciaMedica({
                type: AsistenciaMedicaActionType.actualizarRestriccionesPlan,
                restricciones: serviciosSinRestricciones
            })
            
            return serviciosSinRestricciones.length > 0

        } catch(error) {
            return true;
        }
    }

    /**
     * Retorna el nombre completo del socio capitalizado
     */
    const formatName = (nombre, apellido) => {
        return capitalizeString(`${nombre} ${apellido}`)
    }

    /**
     * Retorna el nombre completo del socio capitalizado
     */
    const handleClick = async (socioSeleccionado) => {
        setLoading(true)

        const planEsValido = await filtrarServiciosPorRestriccion();
        if(!planEsValido) {
            history.push(TELEFONOS_PAGE, {screen: 'seleccionarsocio'})
        } else {
            if (calcularEdad(socioSeleccionado)) {
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.actualizarSocioSeleccionado,
                    socio: socioSeleccionado
                })
                
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.restablecerTriage,
                })
                history.push(FACTORES_RIESGO);
            } else {
                if(!carritoAbandonadoDisable) {
                    setModalEsMenor(true);
                    let mensajeEsMenor = armarMensajeEsMenor(socioSeleccionado);
                    setMensaje(mensajeEsMenor);
                    whatsappEsMenor(socioSeleccionado, socio, apiRequest);
                }
                setShowContactanosModal(!showContactanosModal);
            }
        }
        setLoading(false)
        
    }

    const handleAsistenciaEnCurso = (asistenciaEnCurso) => {
        const servicioEnCurso = {
            servicioEnCurso: asistenciaEnCurso
        }
        history.push(SERVICIO_EN_CURSO, servicioEnCurso);
    }

    /**
     * Calcula la edad basado en la fecha de nacimiento del socio.
     * @param socio
     */
    const calcularEdad = (socio) => {
        let fechaNacimiento = socio.fechaNacimiento.slice(0, 10);
        let mayorEdadSeisMeses = true;
        let today = new Date();
        let birthDate = new Date(fechaNacimiento);
        birthDate.setMonth(birthDate.getMonth() + 6);
        if (birthDate.getTime() > today.getTime()) {
            mayorEdadSeisMeses = false;
        }
        return mayorEdadSeisMeses;
    }

    return (
        <>
        <PageWrapper pageClassName='asistencia-seleccionar-socio' socio={asistenciaMedica.usuario} path={SELECCIONAR_SOCIO}>
            {loading ? <AntecedentesSkeleton /> : <>
                    <IonRow>
                        <IonCol size='12'>
                            <Titulo
                                className='seleccionar-socio__title'
                                titulo='¿Para qui&eacute;n es?'
                                id="titulo-asistencia-seleccionar-socio"
                            />
                        </IonCol>
                        <IonCol size='12'>
                            <Subtitulo 
                                className='seleccionar-socio__subtitle'
                                titulo='Por favor, indicanos qui&eacute;n necesita la asistencia m&eacute;dica.'
                                id="subtitulo-socio"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <ButtonAsistencia
                                id='seleccionar-socio'
                                className='seleccionar-socio__button'
                                arrow={estadoSocioPrincipal.estadoSalus === "CE"}
                                enEspera={estadoSocioPrincipal.estadoSalus === "CO"}
                                creada={estadoSocioPrincipal.estadoSalus === 'AS' || !estadoSocioPrincipal.estadoSalus}
                                titulo={ formatName(socio.nombre, socio.apellidos) }
                                onClick={ () => {
                                    if(estadoSocioPrincipal.estadoSalus !== 'CE'){
                                        handleAsistenciaEnCurso(estadoSocioPrincipal);
                                    }else{
                                        handleClick(socio);
                                    }

                                } }
                            />

                            { socio.familiares.length > 0 && sociosNoDuplicados.map(familiar => {

                                if(asistenciaMedica.asistenciasSolicitadas && asistenciaMedica.asistenciasSolicitadas.length > 0){
                                    const asistenciaSocio = asistenciaMedica.asistenciasSolicitadas.find( asis => asis.principal.nombre.toUpperCase() === familiar.nombre.toUpperCase() )
                                    const filtrarControlVerdes = controlVerdes.filter((socio) => socio.nombre.toUpperCase() === familiar.nombre.toUpperCase());
                                    let tieneServicios = true;

                                    if(filtrarControlVerdes.length > 0) {
                                        tieneServicios = filtrarControlVerdes[0].controlVerdes.length > 0 ? true : false;
                                    }

                                    if(asistenciaSocio && !tieneServicios){
                                        if(ESTADOS_CONFIRMADOS.includes(asistenciaSocio.estadoSalus)){
                                            return (
                                                <ButtonAsistencia
                                                    id='seleccionar-socio'
                                                    className='seleccionar-socio__button'
                                                    key={familiar.id}
                                                    enEspera
                                                    onClick={() => handleAsistenciaEnCurso(asistenciaSocio)}
                                                    onIonBlur={function noRefCheck(){}}
                                                    onIonFocus={function noRefCheck(){}}
                                                    titulo={formatName(familiar.nombre, familiar.apellidos)}
                                                />
                                            )
                                        } else if(ESTADOS_ASIGNADOS.includes(asistenciaSocio.estadoSalus) || !asistenciaSocio.estadoSalus){
                                            return (
                                                <ButtonAsistencia
                                                    id='seleccionar-socio'
                                                    className='seleccionar-socio__button'
                                                    key={familiar.id}
                                                    creada
                                                    onClick={ () => handleAsistenciaEnCurso(asistenciaSocio) }
                                                    onIonBlur={function noRefCheck(){}}
                                                    onIonFocus={function noRefCheck(){}}
                                                    titulo={formatName(familiar.nombre, familiar.apellidos)}
                                                    />
                                            )
                                        }
                                    } else {
                                        return (
                                            <ButtonAsistencia
                                                id='seleccionar-socio'
                                                className='seleccionar-socio__button'
                                                key={familiar.id}
                                                arrow
                                                onClick={ () => handleClick(familiar) }
                                                onIonBlur={function noRefCheck(){}}
                                                onIonFocus={function noRefCheck(){}}
                                                titulo={formatName(familiar.nombre, familiar.apellidos)}
                                                />
                                        )
                                    }     
                                } else {
                                    return (
                                        <ButtonAsistencia
                                            id='seleccionar-socio'
                                            className='seleccionar-socio__button'
                                            key={familiar.id}
                                            arrow
                                            onClick={ () => handleClick(familiar) }
                                            onIonBlur={function noRefCheck(){}}
                                            onIonFocus={function noRefCheck(){}}
                                            titulo={formatName(familiar.nombre, familiar.apellidos)}
                                            />
                                    )

                                }        
                            })}
                        </IonCol>
                    </IonRow>
                    </>}
            </PageWrapper>
            {   modalEsMenor ?
                    <Modal
                        className="modal_web"
                        hideCloseBtn={false}
                        //@ts-ignore
                        component={<Contactanos setupData={setupData} menorSeisMeses={false} sintomas texto={mensaje} titulo={TITULO_INFORMACION_IMPORTANTE}/> }
                        setShowModal={setShowContactanosModal}
                        showModal={showContactanosModal}
                        actionClose={ ()=> { history.push(HOME)	}}
                        id="contactanos"
                    />
                :
                    <Modal
                        className="modal_web"
                        hideCloseBtn={false}
                        //@ts-ignore
                        component={<Contactanos setupData={setupData} screen={'seleccionar_socio'} menorSeisMeses = {true} titulo={TITULO_INFORMACION_IMPORTANTE}/>}
                        setShowModal={setShowContactanosModal}
                        showModal={showContactanosModal}
                        actionClose={ ()=> { history.push(HOME)	}}
                        id="contactanos"
                    />
                }
        </>
    )
}

export default SeleccionarSocio
