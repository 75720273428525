import { RadioButtonGroup } from 'app-principal-componentes-visuales';
import React, { useEffect, useState } from 'react'
import { formatDirection } from '../../../../utils/ubicacionUtils';
import './DatosUbicacionYsocial.scss'
import { capitalizeString, capitalizeWord } from '../../../../utils/utils';
import { Direccion } from '../../../../interfaces/direccion.interface';
import { useHistory } from 'react-router';
import { FORMULARIO_DOMICILIO } from '../../../../utils/constants';

const DatosUbicacionYsocial = ({ direcciones, openFormularioModal, openDeleteDirection, returnSelectedDirection, setSelectedOption } : {[ key: string ]: any }) => {

    const [selected, setSelected] = useState('');
    const [direccionesList, setDireccionesList] = useState([]);
    const history = useHistory();
    
    useEffect(() => {
        direcciones.forEach((direccion, index) => {
            if(direccion.seleccionado) {
                setSelected(index.toString());
                setSelectedOption(direccion);
            }
        });

        let direccionesRadioButton = [];
        direcciones.forEach((direccion:Direccion, index) => {
            let direccionAux = {
                address: capitalizeString(formatDirection(direccion)),
                nota: <>{capitalizeString(direccion.localidad)} <br/> {direccion.observaciones!== undefined ? capitalizeWord(direccion.observaciones) : direccion.observaciones} </>,
                onClick: () => { handleSelected(index) },
                onClickEditar: () => { handleEdit(index) },
                onClickEliminar: () => { handleDelete(index) },
                value: (index.toString())
            }
            direccionesRadioButton.push(direccionAux);
        });
        setDireccionesList(direccionesRadioButton);
    }, [direcciones.length, direcciones])

    const handleEdit = (index) => {
        let selectedDirection = direcciones[index];
        history.push(FORMULARIO_DOMICILIO, {
            selectedDirection: selectedDirection,
            showEliminar: true,    
            direccionesSocio: direcciones,
            fromState: "datosubicacion"     
        })
    }

    const handleDelete = (index) => {
        let selectedDirection = direcciones[index];
        openDeleteDirection(selectedDirection, 'ubicaciones')
    }

    const handleSelected = (index) => {
        setSelected(index.toString());
        returnSelectedDirection(direcciones[index]);
    }
    
    return (
        <>  
            <RadioButtonGroup
                address
                notificacion
                id="datos-ubicacion-radio-button"
                radioList={direccionesList}
                valueSelected={selected}
                titulo=""
            />
        </>
    )
}
export default DatosUbicacionYsocial