import React from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Button, Header, Subtitulo, Titulo } from 'app-principal-componentes-visuales';
import { ASISTENCIA_WEB_ENDPOINT, HOME, LABEL_RIESGO, LABEL_WHATSAPP, SUBTITULO_PLURAL, SUBTITULO_SINGULAR, TELEFONO_EMERGENCIAS, TELEFONO_URGENCIAS_DOS, TELEFONO_URGENCIAS_UNO, TELEFONO_WHATSAPP } from '../../utils/constants';
import { formatearNumeroBoton } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
import './DeshabilitarAsistencia.scss';
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

const DeshabilitarAsistencia = ({ deshabilitarModulos, isWeb, usuario }: any) => {

    const { canalWhatsapp, canalTelefono, redirigirWeb } = deshabilitarModulos;
    const historyNavigate = useHistory();
    
    const validarSubtitulo = () => {
        const cantidadHabilitados = [canalWhatsapp, canalTelefono, redirigirWeb].filter((value) => value).length;
        return cantidadHabilitados === 1 ? SUBTITULO_SINGULAR: SUBTITULO_PLURAL
    }

    const handleClose = () => {
        historyNavigate.push('/');
    }

    const redirigirAsistenciaWeb = () => {
        window.open(ASISTENCIA_WEB_ENDPOINT(), '');
    }

    return (
        <PageWrapper pageClassName={isWeb ? 'asistencia-medica asistencia-medica-deshabilitar-asistencia' : 'asistencia-medica asistencia-medica-deshabilitar-asistencia asistencia-medica-deshabilitar-asistencia-mobile'} socio={usuario} path={HOME}>
            <IonRow>
                <IonCol size='12'>
                    <Titulo
                        fontSm
                        titulo={`Esta sección se encuentra en mantenimiento`}
                    />
                </IonCol>
            </IonRow>
            <IonRow className={isWeb ? 'subtitulo' : ''}>
                <IonCol size='12'>
                    <Subtitulo
                        txt14
                        titulo={validarSubtitulo()}
                    />
                </IonCol>
            </IonRow>

            { redirigirWeb && !isWeb &&
                <IonRow className='row'>
                    <IonCol size='12'>
                        <Button
                            hospital
                            label="Solicitar por web"
                            onClick={redirigirAsistenciaWeb}
                        />
                    </IonCol>
                </IonRow>
            }
            
            { canalWhatsapp &&
                <IonRow className='row'>
                    <IonCol size='12'>
                        <Button
                            href={ !isWeb ? `whatsapp://send?phone=+54${TELEFONO_WHATSAPP()}` :
                                            `https://wa.me/${TELEFONO_WHATSAPP()}`}
                            label={LABEL_WHATSAPP}
                            whatsapp
                        />
                    </IonCol>
                </IonRow>
            }
            
            { canalTelefono && 
                <>
                    <IonRow className='row'>
                        <IonCol size='12'>
                            <Button
                                href={`tel:${TELEFONO_URGENCIAS_UNO()}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_UNO()!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='row'>
                        <IonCol size='12'>
                            <Button
                                href={`tel:${TELEFONO_URGENCIAS_DOS()}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_DOS()!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className='row'>
                        <IonCol size='12'>
                            <Subtitulo
                                titulo={LABEL_RIESGO}
                                txtRed
                            />
                            <Button
                                colorRed
                                href={`tel:${TELEFONO_EMERGENCIAS()}`}
                                label={formatearNumeroBoton(TELEFONO_EMERGENCIAS!())}
                                primary
                            />
                        </IonCol>
                    </IonRow>
                </>
            }
        </PageWrapper>
    )
}

export default DeshabilitarAsistencia