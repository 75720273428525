import React from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Button, Header, Subtitulo } from 'app-principal-componentes-visuales';
import { useHistory } from 'react-router';
import { HOME, LABEL_RIESGO, LABEL_WHATSAPP, SEGUIMIENTO_VACIO_STRING, TELEFONO_EMERGENCIAS, TELEFONO_URGENCIAS_DOS, TELEFONO_URGENCIAS_UNO, TELEFONO_WHATSAPP } from '../../utils/constants';
import './SeguimientoVacio.scss';
import { formatearNumeroBoton } from '../../utils/utils';

const SeguimientoVacio = () => {

    const historyNavigate = useHistory();

    const handleClose = ()=>{
        historyNavigate.push(HOME);
    }

    return (
        <IonPage className='asistencia-medica asistencia-medica-seguimiento-vacio'>
            <Header 
                headerCartilla 
                titulo='Contactanos' 
                colorWhite
                fondoCeleste
                closeBtn
                onClickCloseBtn={handleClose} 
                id="header-asistencia-medicca-contactanos"
            />
            <IonContent>
                <IonGrid className="seguimiento__grid">
                    <IonRow className='subtitulo'>
                        <IonCol size='12'>
                            <Subtitulo
                                txt18
                                titulo={SEGUIMIENTO_VACIO_STRING}
                                id="subtitulo-seguimiento"
                            />
                        </IonCol>
                    </IonRow> 
                    <IonRow>
                        <IonCol size='12'>
                            <Button
                                href={ `whatsapp://send?phone=+54${TELEFONO_WHATSAPP()}` }
                                label={LABEL_WHATSAPP}
                                whatsapp
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <Button
                                href={`tel:${TELEFONO_URGENCIAS_UNO()}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_UNO()!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <Button
                                href={`tel:${TELEFONO_URGENCIAS_DOS()}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_DOS()!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <Subtitulo
                                titulo={LABEL_RIESGO}
                                txtRed
                                id="subtitulo-riesgo"
                            />
                            <Button
                                colorRed
                                href={`tel:${TELEFONO_EMERGENCIAS()}`}
                                label={formatearNumeroBoton(TELEFONO_EMERGENCIAS()!)}
                                primary
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )

}

export default SeguimientoVacio;