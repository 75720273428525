import { YsocialGlobalState } from "../interfaces/global-state/ysocial.state.interface";

export const getYsocialInitialState = (): YsocialGlobalState | undefined => {
        return {
                usuario: undefined,
                serviciosEnCursoSolicitados: undefined,
                socioSeleccionado: undefined,
                datosParaTriage: undefined,
                direccionSeleccionada: undefined,
                restriccionesPlan: undefined,
                asistenciasSolicitadas: []
        };
}