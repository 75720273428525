import { Environment } from "../environment/Environment";

/**
 * Constante de EndPoint
 */
export const NORMALIZADOR_ENDPOINT = () => Environment.ENV.NORMALIZADOR_ENDPOINT;
export const BACKEND_ENDPOINT = () => Environment.ENV.BACKEND_ENDPOINT;
export const ASISTENCIA_WEB_ENDPOINT = () => Environment.ENV.ASISTENCIA_WEB_ENDPOINT;
export const GOOGLE_MAPS_API_KEY = () => Environment.ENV.GOOGLE_MAPS_API_KEY;
export const YOIZEN_ENDPOINT = () => Environment.ENV.YOIZEN_ENDPOINT;
export const SOCIO_PRINCIPAL = "socio-principal";
export const SOCIO_FAMILIAR = "socio-familiar";
export const SOCIO_SALUS = "socio-salus/socio/";
export const GRUPO_SALUS = "socio-salus/grupo/";
export const CASOS_SALUS = "socio-salus/casos/";
export const CASOS_HISTORIAL_SALUS = "caso/historial/";
export const CONTROL_VERDES = "control";
export const CANAL = "canal";
export const CLASIFICACION = "clasificacion";
export const ANTECEDENTES = "antecedentes";
export const TRIAGE = "triage";
export const DIRECCION_PROVINCIA = "direccion/provincia"
export const SALUS_SERVICE = "contacto-salus";
export const SERVICIO_MULTIPLE = "contacto-n-salus";
export const AUTH_SERVICE_URL = "auth/";
export const TOKEN_URI = "token/";
export const OAUTH_TOKEN_URI = "oauth2/token";
export const REFRESH_TOKEN_URL = "refresh-token/";
export const INSTROSPECT = "introspect"
export const REVOKE = "revoke-token"
export const CASO_SERVICE_URL = "caso";
export const CASO_SERVICE = "caso/";
export const CASOS_SERVICIOS_MULTIPLES = "caso/multiples";
export const CASO_BY_CONTRATO_SERVICE_URL = "contrato/";
export const CASO_BY_NRO_CASO_SERVICE_URL = "nro-caso";
export const RUSS_URL = "contacto-russ/patient";
export const COSEGURO = "coseguro/obtener-coseguro/";
export const DISPONIBILIDAD = "disponibilidad-servicio/";
export const REGLA = "regla";
export const SERVICIO = "servicio";
export const MENSAJERIA = "mensajeria";
export const loginHintDefault =
  "&login_hint=" +
  encodeURIComponent(
    "{" + encodeURIComponent('{"A":"D","B":"SAM","C":"estadoHint"}') + "}"
  );
export const AUTHORIZATION_CODE = 'authorization_code'
export const redirectTo = "&redirectTo=https://www.osde.com.ar";
//TODO cambiar por el real
export const CARRITO_ABANDONADO = "carrito-abandonado";
export const PLAN = "plan/"
export const RESTRICCION = "restriccion"
export const DESHABILITACION = "deshabilitacion";


/**
 * Numeros de Telefono
 */
export const TELEFONO_WHATSAPP = () => Environment.ENV.TELEFONO_WHATSAPP;
export const TELEFONO_EMERGENCIAS = () => Environment.ENV.TELEFONO_EMERGENCIAS;
export const TELEFONO_URGENCIAS_UNO = () => Environment.ENV.TELEFONO_URGENCIAS_UNO;
export const TELEFONO_URGENCIAS_DOS = () => Environment.ENV.TELEFONO_URGENCIAS_DOS;
export const LABEL_RIESGO = "Riesgo de vida";
export const LABEL_WHATSAPP = "Escribinos por WhatsApp";

/**
 * Mensajes
 */
export const NO_ENCUENTRO_SINTOMA = 'No encuentro el s&iacute;ntoma';
export const CONFIRMAR_SINTOMAS = 'Confirmar s&iacute;ntomas';


/**
 * Clases CSS
 */
export const ACTIVAR_ELEMENTO = 'active';

/** Breakpoint para Split Pane */
export const DESKTOP_SIZE = 'lg';
/** Breakpoint para usar en archivos JS, en conjunto con window.innerWidth */
export const DESKTOP_MIN_WIDTH = 992;
/** Clase para elementos que solo deben visualizarse en dispositivos moviles */
export const MOBILE_ELEMENT_CLASS = 'ion-hide-lg-up';
/** Clase para elementos que solo deben visualizarse en dispositivos de escritorio */
export const DESKTOP_ELEMENT_CLASS = 'ion-hide-md-down';

/**
 * Salus
 */
export const INTERCONSULTA= "IC";
export const VISITA_MEDICA = "VM";
export const EMERGENCIA = "EM";
export const ESTUDIO = "ES";
export const CODIGO_TRASLADO = "TR";
export const TRATAMIENTO_EN_DOMICLIO = "TD";

export const CONSULTA_MEDICA_ONLINE = "CMO";
export const VISITA_BREVEDAD = "VB";
export const CODIGO_VERDE = "CV";
export const INTERCONSULTA_GEN = "GEND";
export const CODIGO_CANAL_ENTRADA = "AppPM";
export const CODIGO_CANAL_ENTRADA_YSOCIAL = "WSPWEB";
export const RESPUESTA_SALUS_AFIRMATIVA = "R: Si";
export const RESPUESTA_SALUS_NEGATIVA = "R: No";
export const FIRMA_SALUS = "SYS";

export const PREFIJO_MOTIVO = "AppPM ";
export const PREFIJO_MOTIVO_YSOCIAL = "WSPWEB ";
export const PREFIJO_INTERNACIONAL_TELEFONO = "54";
export const PREFIJO_CELULARES = "9";
export const PREFIJO_INTERNACIONAL_LENGTH = 2;
export const PREFIJO_CELULARES_LENGTH = 1;
export const PREFIJO_WHATSAPP_INTERNACIONAL_ARGENTINA = '+549';
export const PREFIJO_WHATSAPP_WEB = 'https://wa.me/';
export const PREFIJO_WHATSAPP_INTERNACIONAL_ARGENTINA_WEB = '549';


export const AUTHORIZE_PATH = "/authorize"

//Historial de atencion
export const CONSULTA_MEDICA_ONLINE_DESCRIPCION = "Atención médica virtual de Urgencias";
export const VISITA_BREVEDAD_DESCRIPCION = "Visita Médica a domicilio";
export const ASISTENCIA_MEDICA = "Asistencia médica virtual de urgencias";
export const SERVICIO_AMBULANCIA = "Servicio de Ambulancia";
export const HISOPADO_COVID = "Hisopado Covid-19";
export const LABORATORIO_DOMICILIO = "Laboratorio a domicilio";
export const DIAGNOSTICO_POR_IMAGENES_A_DOMICILIO= "Diagnóstico por Imágenes a domicilio";
export const INTERNACION = "Internación";
export const ENFERMERIA = "Enfermería a domicilio";
export const KINESIOLOGIA = "Kinesiología a domicilio";
export const FONOAUDIOLOGIA = "Fonoaudiología a domicilio";
export const PSIQUIATRIA = "Visita Médica a domicilio";
export const TRASLADO_PROGRAMADO = "Servicio de Traslado programado";
export const TRASLADO = "Servicio de Traslado";

//ESTADO DE LOS CASOS RUSS
export const PLANNED = "PLANNED";
export const FINISHED = "FINISHED";
export const CANCELLED = "CANCELLED";
export const PLANNED_SIGLA = "E";
export const FINISHED_SIGLA = "F";
export const CANCELLED_SIGLA = "A";

//MENSAJES HISTORIAL DE ATENCIONES
export const EN_CURSO = "Asistencias en curso";
export const FINALIZADAS = "Asistencias finalizadas";

//TABS
export const HOME_ICON = 'assets/icon/home.svg';
export const RIESGO_DE_VIDA_ICON = 'assets/icon/riesgo-de-vida.svg';
export const HISTORY_ICON = 'assets/icon/historial.svg';
export const PROFILE_ICON = 'assets/icon/perfil.svg';

export const HOME_SELECTED_ICON = 'assets/icon/home_selected.svg';
export const RIESGO_DE_VIDA_SELECTED_ICON =  'assets/icon/riesgo-de-vida-selected.svg';
export const HISTORY_SELECTED_ICON =  'assets/icon/historial_selected.svg';
export const PROFILE_SELECTED_ICON = 'assets/icon/perfil_selected.svg';

//GEOLOCALIZACION
export const LIMITE_DISTANCIA = 1000;

//VALIDACION DE CAMPOS DE TEXTO
export const FORBIDDEN_CHAR_REGEX = /[^\u0000-\u00ff]/u;
export const BUENOS_AIRES = 'Buenos Aires';
export const CABA = 'CIUDAD AUTONOMA BUENOS AIRES';

/**
 * Constante necesaria para el armado de mensaje de whatsapp
 */
export const QUERYPARAMTEXT = '?text=';
export const SIN_ANTECEDENTES ='No poseo ning\u00fan antecedente mencionado';
export const SIN_ANTECEDENTES_SALUS ='Sin Antecedentes de Salud.';

//ESTADOS DE SERVICIOS SALUS
export const ASIGNADO = "AS";
export const CONFIRMADO = "CO";
export const ARRIBADO = "AR";
export const CERRADO = "CE";
export const TP = "TP";
export const TV = "TV";
export const RC = "RC";
export const EN = "EN";

export const ESTADOS_ASIGNADOS = [ASIGNADO, EN, RC];
export const ESTADOS_CONFIRMADOS = [CONFIRMADO, ARRIBADO, TP, TV];
export const SERVICIOS_COMUNES = [INTERCONSULTA, VISITA_MEDICA, ESTUDIO, CODIGO_TRASLADO, TRATAMIENTO_EN_DOMICLIO];
export const CLASIFICACIONES_CON_CODIGO_SEGURIDAD= [VISITA_BREVEDAD,CODIGO_VERDE];

export const NACIONAL = 'Nacional';
export const PANTALLA_TELEFONO = 'urgencias';
export const PANTALLA_SERVICIO = 'pedido-servicio';
//PARENTESCOS. Minuscula y sin acentos.
export const CONYUGE = "conyuge";
export const CONYUGE_ADICIONAL = "conyuge adicional";
export const CONCUBINA_O = "concubina/o";
export const TITULAR = "titular";

//REGEX. 
//Elimina acentos. Tratamiento especial con la Ñ.
export const SIN_ACENTO = /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi;

//Mensaje de error Coseguro.
export const ERROR_COSEGURO = "Este servicio podría tener cargos adicionales los cuales no podemos informarte en este momento.";

//FILTRO RUSS
export const FILTRO_RUSS = ["SALUS", "CMO"];

/** 
 * Constantes para la navegacion
 */
export const HOME = '/asistenciamedica'
export const SELECCIONAR_SOCIO = '/asistenciamedica/seleccionarsocio'
export const FACTORES_RIESGO = '/asistenciamedica/antecedentes'
export const VERIFICAR_DATOS_CONTACTO = '/asistenciamedica/datoscontacto'
export const VERIFICAR_DATOS_UBICACION = '/asistenciamedica/datosubicacion'
export const SINTOMAS = '/asistenciamedica/sintomas'
export const PREGUNTAS = '/asistenciamedica/preguntas'
export const CANCELAR_SOLICITUD = '/asistenciamedica/cancelar'
export const FEEDBACK_SERVICIO_EN_CURSO = '/asistenciamedica/fservicioencurso'
export const RESUMEN = '/asistenciamedica/resumen'
export const PEDIDO_SERVICIO = '/asistenciamedica/pedidoservicio'
export const ASISTENCIA_CREADA = '/asistenciamedica/asistenciacreada'
export const ADS_PRINCIPAL = '/homePpal'
export const SERVICIO_EN_CURSO = '/asistenciamedica/servicioencurso'
export const HISTORIAL_ATENCIONES = '/asistenciamedica/historialatenciones'
export const DETALLE_CASO = '/asistenciamedica/historialatenciones/detallecaso'
export const TELEFONOS_PAGE = '/asistenciamedica/telefonos'
export const SEGUIMIENTO_VACIO = '/asistenciamedica/seguimientovacio';

/**
 * Constantes para navegacion Ysocial
 */
export const DATOS_CONTACTOS_YSOCIAL = '/asistencia/datoscontactosysocial';
export const FORMULARIO_CONTACTO_YSOCIAL = '/asistencia/formulariocontactosysocial';
export const SELECCIONAR_DIRECCION_YSOCIAL = '/asistencia/seleccionardireccionysocial';
export const RESUMEN_YSOCIAL = '/asistencia/resumenysocial';
export const ASISTENCIA_SOLICITADA_YSOCIAL = '/asistencia/asistenciasolicitadaysocial';
export const CANCELAR_SOLICITUD_YSOCIAL = '/asistencia/cancelar'
export const FORMULARIO_DOMICILIO = '/asistencia/formulariodomicilio';
export const YSOCIAL_FEEDBACK = '/asistencia/feedback'

/**
 * Constantes para ysocial web
 */
export const PARRAFO_SERVICIO_EN_CURSO = "Este socio ya posee un servicio en curso, en caso de que necesite mas información, deberá volver a WhatsApp para validar su estado.";
export const PARRAFO_SIN_GRUPO_FAMILIAR = "Este socio no se encuentra entre tu grupo familiar, ante cualquier duda podés contactarte con nuestro equipo.";
export const PARRAFO_ERROR_GENERICO = "Estamos trabajando para resolverlo. Podés contactarnos a través de WhatsApp o llamada telefónica."
export const CANCELAR_ASISTENCIA = "Asistencia cancelada";
export const ERROR_SERVICIO_EN_CURSO = "Asistencia error - servicios en curso";
export const ASISTENCIA_CREADA_YSOCIAL = "Asistencia creada";
export const ASISTENCIA_ERROR_YSOCIAL = "Error al crear la asistencia";
export const GRUPO_FAMILIAR_ERROR_YSOCIAL = "Error al iniciar sesion - no se encuentra socio en grupo familiar";
export const TITULO_FEEDBACK = "No se pudo realizar la solicitud";

/**
 * Factores
 */
export const FACTOR_CLAVE_ID = 8

/**
 * Status de Merlin
 */
export const CORREGIDO = 'CO';
export const NO_ENCONTRADO = 'NE';
export const DUDOSO = 'DU';
export const SIN_DATOS = 'SD';
export const NO_PARSEADO = 'NP';
export const ALTURA_INVALIDA = 'AI';
export const CALLE_INVALIDA = 'CI';
export const DUDA_ESTANDAR = 'DE';

/**
 * Whatsapp saliente
 */
export const serviciosVMAceptados = ["CMO","CV", "AR","VB", "VC"];
export const serviciosICAceptados = ["GEND","DEND", "COVD"];
export const serviciosEMAceptados = ["CM","CR"];

export const SERVICIO_VM = "VM";
export const SERVICIO_IC = "IC";
export const SERVICIO_EM = "EM";

/**
 * Timer Servicios en Curso
 */

export const DELAY_REQUEST_CASOS_EN_CURSO = 5

/** 
 * Titulos para el modal de telefonos 
*/
export const TITULO_ASISTENCIA_MEDICA = "Asistencia médica";
export const TITULO_CONTACTANOS = "Contáctanos";
export const TITULO_INFORMACION_IMPORTANTE = "Información importante";
export const TITULO_PREGUNTA = "¿Necesitás comunicarte con Urgencias?"

/**
 * Ubicaciones excluidas
 */
export const LOCALIDADES_EXCLUIDAS = ["CIUDAD AUTONOMA BUENOS AIRES", "CAPITAL FEDERAL"];

/**
 * Deshabilitar asistencia
 */
export const SUBTITULO_PLURAL = "Para solicitar una asistencia médica de urgencia contactate por estos medios";
export const SUBTITULO_SINGULAR = "Para solicitar una asistencia médica de urgencia contactate por este medio";

/**
 * seguimiento vacio
 */
export const SEGUIMIENTO_VACIO_STRING = "Tenemos un inconveniente para acceder a la información de la asistencia médica. Para cualquier información comunicate directamente con nosotros";
/** 
 * Formas de escribir capital 
 */
export const CAPITAL = "CAPITAL FEDERAL";