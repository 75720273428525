import React, { useState } from "react";
import "./CancelarYsocial.scss";
import { useHistory } from "react-router";

import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Feedback } from "app-principal-componentes-visuales";
import { CANCELAR_ASISTENCIA, PARRAFO_ERROR_GENERICO, TITULO_FEEDBACK } from "../../../utils/constants";
import { saveYoizen } from "../../../api/mensajeriaService";
import { useRequest } from "../../../hooks/webUseRequest";
import { cerrarSesion } from "../../../helpers/WebYsocial/sesionHelper";

const CancelarYsocial = () => {

    const history = useHistory();
    const [waitingBtn, setWaitingBtn] = useState(false);
    const [yoizen, setYoizen] = useState(true);

    const { apiRequest } = useRequest();

    const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));

    const cancelWithAnalytics = () => {
        setWaitingBtn(true);
        const message = CANCELAR_ASISTENCIA;
        saveYoizen(ysocial.ysocialToken, message, 204, apiRequest)
            .then(async () => {
                await cerrarSesion();
            }).catch(error => {
                console.error("Error mensaje POST whatsapp", error);
                setYoizen(false);
            })
            .finally(() => {
                setWaitingBtn(false);
            });
    }

    return (
        <IonPage>
            <IonContent >
                <div className="ysocial-background-page">
                    <IonGrid className="ysocial_content-container ysocial-padding">
                        <IonRow>
                            <IonCol size='12'>
                                {
                                    yoizen ?
                                        <Feedback
                                            notOsde
                                            titulo={"¿Querés cancelar?"}
                                            parrafo={"En caso de hacerlo, perderás todos los datos cargados y no se realizará la solicitud de asistencia médica"}
                                            handsPhoneExclamation
                                            labelButtonCancel={"Mejor no"}
                                            onClickCancel={() => history.goBack()}
                                            labelButtonOk={"Sí, cancelar"}
                                            onClickOk={cancelWithAnalytics}
                                            id="feedback-cancelar"
                                            waiting={waitingBtn}
                                        />
                                        :
                                        <Feedback
                                            notOsde
                                            waiting={waitingBtn}
                                            titulo={TITULO_FEEDBACK}
                                            parrafo={PARRAFO_ERROR_GENERICO}
                                            handsPhoneExclamation
                                            labelButtonOk={"Volver a whatsapp"}
                                            onClickOk={() => cerrarSesion()}
                                            id="feedback-ysocial"
                                        />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default CancelarYsocial;