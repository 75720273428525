import React, { useState, useContext } from "react";
import {
  IonRow,
  IonCol,
  IonGrid,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonLabel,
} from "@ionic/react";
import "./SessionHeader.scss";
import { HOME, SELECCIONAR_SOCIO } from "../../../utils/constants";
import AsistenciaHeader from "../AsistenciaHeader/AsistenciaHeader";
import { Socio } from "../../../models/socioPrincipal.model";
import { cerrarSesionHelper } from "../../../helpers/cerrarSesion";
import { useHistory } from "react-router";
import { ModalConfirmacionCierreCompletoSesion } from "../../Modals/CerrarSesion/CerrarSesionModal";
import { AsistenciaMedicaContext } from "../../../contexts/AsistenciaMedicaContext";
import { Header } from "app-principal-componentes-visuales";
import { YsocialContext } from "../../../contexts/YsocialContext";

interface SessionHeaderProps {
  socioPrincipal: Socio,
  path: String,
  titulo?: string,
  notCancel?: boolean,
  notBack?: boolean,
  handlerHandleBack?: () => void,
}

const SessionHeader = ({ socioPrincipal, path, titulo, notCancel, notBack, handlerHandleBack }: SessionHeaderProps) => {

  const history = useHistory();
  const [showModalCierreSesion, setShowModalCierreSesion] = useState(false);

  const { isWeb = null } = useContext(AsistenciaMedicaContext);
  const { isWeb: isWebYsocial = null } = useContext(YsocialContext);

  const handleCancelarCerrarSesion = () => {
    setShowModalCierreSesion(true)
  }

  const handleClose = () => {
    history.push('/');
  }

  return (
    <>
      <IonHeader>
        <IonGrid>
          {
            (isWeb ) &&
            <>
              <IonRow className="row-osde no-mobile">
                    <IonCol size="12">
                      <a href="https://www.osde.com.ar">
                        <img className="logo-osde" src="/assets/icon/logo_osde_blue.svg" />
                      </a>
                    </IonCol>
              </IonRow>
              <IonRow className="row-session-bar ion-justify-content-end ion-align-items-center no-mobile">
                <IonCol size="auto" className="user-name">
                  <IonLabel>
                    Hola {socioPrincipal?.nombre} {socioPrincipal?.apellidos}
                  </IonLabel> 
                </IonCol>
                <IonCol
                  size="auto"
                  className="logout-button"
                  onClick={handleCancelarCerrarSesion}
                >
                  <IonLabel>
                    Salir
                  </IonLabel>
                </IonCol>
              </IonRow>
            </>
          }
          {
            path === HOME ?
              <IonRow>
                <IonCol size="12">
                  {isWeb ?
                    <IonToolbar className="asistencia-tool-bar">
                      <IonTitle className="asistencia-title">
                        Asistencia Médica de Urgencias
                      </IonTitle>
                      <IonCol
                        size="auto"
                        className="logout-button no-desktop logoutX"
                        onClick={handleCancelarCerrarSesion}
                      >
                        <IonLabel>
                          Salir
                        </IonLabel>
                      </IonCol>
                    </IonToolbar>
                    :
                    <Header
                      headerCartilla
                      titulo='Asistencia M&eacute;dica de Urgencias'
                      colorWhite
                      fondoCeleste
                      closeBtn
                      onClickCloseBtn={handleClose}
                      id="header-asistencia-medica-home"
                    />
                  }
                </IonCol>
              </IonRow>
              :
              <AsistenciaHeader
                titulo={titulo ? titulo : 'Solicitar asistencia'}
                showBackButton={path === SELECCIONAR_SOCIO || notBack}
                showCancelPage={path !== SELECCIONAR_SOCIO && !notCancel}
                notCancel={notCancel}
                handlerHandleBack={handlerHandleBack}
              />
          }
        </IonGrid>
      </IonHeader>
      <ModalConfirmacionCierreCompletoSesion
        showModal={showModalCierreSesion}
        setShowModal={setShowModalCierreSesion}
        handleCerrarSesion={() => cerrarSesionHelper(history)}
        handleCancelarCerrarSesion={() => setShowModalCierreSesion(false)}
      />
    </>
  );
};

export default SessionHeader;
