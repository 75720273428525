import React, { useContext, useEffect, useState } from 'react'
import { IonCol, IonRow } from '@ionic/react';
import { YsocialContext } from '../../../contexts/YsocialContext';
import { ASISTENCIA_SOLICITADA_YSOCIAL, CABA, CAPITAL, DATOS_CONTACTOS_YSOCIAL, ERROR_SERVICIO_EN_CURSO, NACIONAL, PANTALLA_TELEFONO, PARRAFO_SERVICIO_EN_CURSO, PEDIDO_SERVICIO, RESUMEN_YSOCIAL, TITULO_FEEDBACK } from '../../../utils/constants';
import { Button, CardSolicitarAsistencia, Separador, Subtitulo, Texto1Linea, Titulo } from 'app-principal-componentes-visuales';
import { calcularEdad, capitalizeString, formatearNumeroInputCelular, obtenerPreguntas } from '../../../utils/utils';
import { formatDirection } from '../../../utils/ubicacionUtils';
import { useRequest } from '../../../hooks/webUseRequest';
import { getCoseguro, getDisponibilidad, getReglas } from '../../../api/asistenciaMedicaService';
import { Regla } from '../../../interfaces/regla/regla.interface';
import { ReglaAlternativa } from '../../../interfaces/regla/reglaAlternativa.interface';
import { Direccion } from '../../../interfaces/direccion.interface';
import { Alternativa } from '../../../interfaces/regla/alternativa.interface';
import { ClasificacionModel } from '../../../interfaces/clasificacion.interface';
import { Servicio } from '../../../interfaces/servicio.interface';
import { Disponibilidad } from '../../../interfaces/disponibilidad.interface';
import { Casos, InfoCaso } from '../../../models/caso.model';
import { Pregunta } from '../../../interfaces/pregunta.interface';
import { cargarDatosYsocial, crearInfoCasoYsocial } from '../../../helpers/WebYsocial/datosSalusHelper';
import { CrearDerivarCasoYsocial, ServiciosMultiplesResponse } from '../../../interfaces/ysocial/crearDerivarCasoYsocial.interface';
import { saveCasoServicioYsocial, sendCasoYsocial } from '../../../api/ysocial/casoServiceYsocial';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';
import FeedbackYsocial from '../FeedbackYsocial/FeedbackYsocial';
import ErrorTecnicoYsocial from '../ErrorTecnicoYsocial/ErrorTecnicoYsocial';
import "./ResumenYsocial.scss"

const ResumenYsocial = ({ history }) => {
    const { apiRequest } = useRequest();
    const { dirSeleccionada } = history.location.state;
    const { ysocial } = useContext(YsocialContext);
    const [contactoAccordion, setContactoAccordion] = useState(true);
    const [direccionAccordion, setDireccionAccordion] = useState(true);
    const [atencionAccordion, setAtencionAccordion] = useState(true);
    const [waitingButton, setWaitingButton] = useState(false)
    //validar donde quedan guardados los sintomas
    const [preguntasFinal, setPreguntasFinal] = useState([]);
    const [sintomaFinal, setSintomaFinal] = useState<any>({});
    const [showSintoma, setShowSintoma] = useState('');
    const [error, setError] = useState(false);
    const [ysocialFeedback, setYsocialFeedback] = useState(false);
    const usuario = ysocial.socioSeleccionado;
    const ysocialStorage = JSON.parse(sessionStorage.getItem("ysocial"));

    let tarjetaTelefono = false;
    let clasificaciones: ClasificacionModel[] = [];
    let ubicacionSocio = NACIONAL;
    let admiteDisponibilidadEnZona = true;
    let disponibilidadFinal = [];
    let coseguroFinal: any;
    let clasificacionSeleccionada;
    let preguntasHelper: Pregunta[];
    let infoCaso = new InfoCaso();

    useEffect(() => {

        let respuestas = [];

        //itero el array para obtener la respuesta correspondiente a cada pregunta y guardarlas en respuestasFinal
        let ysocialPreguntas = ysocialStorage.ysocialPreguntas
        const splitRespuestas = ysocialPreguntas.split('-');
        splitRespuestas.forEach((preguntaRespuesta, index) => {
            let indexRespuesta = preguntaRespuesta.indexOf('?')
            let respuestaSub = preguntaRespuesta.substring(indexRespuesta + 1, preguntaRespuesta.length)
            let respuesta = respuestaSub.trim().toLowerCase()
            respuestas.push(respuesta)
        });
        setPreguntasFinal(respuestas)
        //obtengo el sintoma y las preguntas
        const sintomas = localStorage.getItem('sintomas');
        const sintomasParse = JSON.parse(sintomas);
        const ysocialSintoma = ysocialStorage.ysocialSintoma;
        const sintoma = sintomasParse.find((sintoma) => sintoma.descripcion.includes(ysocialSintoma));
        setSintomaFinal(sintoma);
        setShowSintoma(ysocialSintoma);
    }, [])

    const cargarCoseguro = async (provincia, localidad, servicio, clasificacion, pantalla) => {
        try {
            const coseguroResponse = await getCoseguro(usuario.nroContrato, servicio, clasificacion.descripcion, provincia, localidad, apiRequest)
            clasificacion.coseguro = coseguroResponse.valorFinal;
            clasificacion.errorCoseguro = '';
            coseguroFinal = {
                coseguroValorFinal: coseguroResponse.valorFinal,
                errorCoseguro: ''
            }
        } catch (error) {
            clasificacion.errorCoseguro = error;
            coseguroFinal = {
                coseguroValorFinal: 0,
                errorCoseguro: clasificacion.errorCoseguro
            }
            setWaitingButton(false);
            console.error('Ocurrio un error con el request de coseguro:', error);
        } finally {
            clasificaciones.push(clasificacion);
        }
        return pantalla = PEDIDO_SERVICIO;
    }

    /**
     * Metodo que realiza la peticion a disponibilidad en zona para las reglas que contengan segun su clasificacion una disponibilidad activa
     */
    const analisisDisponibilidadPorPrioridad = async (reglaAlternativa, provincia, localidad, barrio, esMenor) => {
        let pantalla: any = PANTALLA_TELEFONO;
        for (let i = 0; i < reglaAlternativa.length; i++) {
            let alternativa: Alternativa = reglaAlternativa[i].alternativa;
            let clasificacion: ClasificacionModel = alternativa.clasificacion;
            let servicio: Servicio = alternativa.servicio;
            let disponibilidad: Disponibilidad = clasificacion.disponibilidad;
            if (alternativa.salida != 'servicio') {
                tarjetaTelefono = true;
                break;
            } else if (disponibilidad.activa) {
                if (ubicacionSocio === NACIONAL && (disponibilidad.ubicacion === NACIONAL || disponibilidad.ubicacion === 'Ambas')) {
                    if (clasificacion.consultaDisponibilidad && admiteDisponibilidadEnZona) {
                        try {
                            const disponibilidad = await getDisponibilidad(provincia, localidad, barrio, servicio.descripcion, clasificacion.descripcion, esMenor, apiRequest);
                            let disponibilidades = [];
                            disponibilidades.push(disponibilidad);
                            disponibilidadFinal.push(disponibilidad)
                            if (disponibilidad.disponibilidad) {
                                pantalla = await cargarCoseguro(provincia, localidad, servicio.descripcion, clasificacion, pantalla);
                            } else {
                                setError(true);
                                console.error("No existe disponibilidad en zona para esa dirección: ", error);
                            }
                        } catch (error) {
                            setError(true);
                            console.error("Ocurrio un problema al realizar la peticion a disponibilidad en zona:", error);
                        };
                    } else {
                        pantalla = await cargarCoseguro(provincia, localidad, servicio.descripcion, clasificacion, pantalla);
                    }
                }
            }
        }
        return pantalla;
    }

    /**
     * Metodo que realiza filtra las reglas alternativas por prioridad y luego realiza el analisis de cuales realizar dicho request a disponibilidad en zona
     */
    const consultoDisponibilidadEnZona = async (reglaAlternativa) => {
        let direccion: Direccion = dirSeleccionada;
        let provincia = direccion.provincia === CABA ? CAPITAL : direccion.provincia;
        let localidad = direccion.localidad;
        let barrio = direccion.barrio;
        let esMenor = calcularEdad(usuario.fechaNacimiento) < 18;
        let prioridadesFiltradas;
        let pantalla;
        for (let i = 0; i < reglaAlternativa.length; i++) {
            prioridadesFiltradas = reglaAlternativa.filter(ra => ra.prioridad == reglaAlternativa[i].prioridad);
            pantalla = await analisisDisponibilidadPorPrioridad(prioridadesFiltradas, provincia, localidad, barrio, esMenor);
            if (tarjetaTelefono || clasificaciones.length > 0) {
                break;
            } else {
                reglaAlternativa = reglaAlternativa.filter(ra => ra.prioridad != reglaAlternativa[i].prioridad);
                i = -1;
            }
        }
        return pantalla;
    }

    /**
     * Metodo encargado de ordenar las reglas alternativas por su prioridad y realizar la consulta a disponibilidad en zona  
     */
    const validacionDeClasificacion = (reglaAlternativa: ReglaAlternativa[]) => {
        return new Promise(async (resolve, reject) => {
            reglaAlternativa.sort((a, b) => (a.prioridad < b.prioridad) ? -1 : 1);
            let pantalla = await consultoDisponibilidadEnZona(reglaAlternativa);
            resolve(pantalla);
        });
    }

    const cargarDatosCaso = async () => {
        let icLogin = ysocial.usuario.ic;
        const caso: CrearDerivarCasoYsocial = await cargarDatosYsocial(icLogin, usuario, sintomaFinal, dirSeleccionada, preguntasHelper, clasificacionSeleccionada);
        enviarCasoSalus(caso);
    }

    const enviarCasoSalus = async (caso: CrearDerivarCasoYsocial) => {
        try {
            const serviciosMultiplesResponse = await sendCasoYsocial(caso, apiRequest);
            if (serviciosMultiplesResponse.status === 207) {
                let serviciosMultiples: ServiciosMultiplesResponse = serviciosMultiplesResponse?.data;
                if (serviciosMultiples?.casos[0].estatus === 200) {
                    let infoCasoYsocial = await crearInfoCasoYsocial(usuario, sintomaFinal, dirSeleccionada, serviciosMultiples);
                    persistirEnBD(infoCasoYsocial);
                } else if (serviciosMultiples?.casos[0].estatus === 412) {
                    console.error("El socio posee un servicio en curso.")
                    setYsocialFeedback(true);
                } else {
                    console.error("Se produjo un error al pedir una asistencia: ", error);
                    setError(true);
                }
            }
        } catch (error) {
            console.error({ error });
            setError(true);
        } finally {
            setWaitingButton(false);
        }
    }

    /**
     * metodo que se encarga de crear un objeto infoCaso y guardarlo en la base de datos
     */
    const persistirEnBD = async (infoCasoYsocial) => {
        if (infoCasoYsocial.length > 0) {
            try {
                let casos: Casos = new Casos();
                casos.casos = infoCasoYsocial;
                casos.numeroIc = usuario.ic.toString();
                const persistirEnBDResponse = await saveCasoServicioYsocial(casos, apiRequest);

                if (persistirEnBDResponse.status === 200) {
                    console.info(`Caso guardado correctamente en la db.`);
                }
            } catch (error) {
                console.error("Error al persistir en bd: ", error);
            } finally { 
                history.push(ASISTENCIA_SOLICITADA_YSOCIAL);
            }
        }
        
    }

    const handleBack = async () => {
        history.push(DATOS_CONTACTOS_YSOCIAL);
    }

    const handleBotonContinuar = () => {
        setWaitingButton(!waitingButton);
        preguntasHelper = obtenerPreguntas(sintomaFinal, preguntasFinal);
        let preguntasRequest: any[] = preguntasHelper;
        //primer servicio llamar a reglas
        getReglas(preguntasRequest, apiRequest)
            .then((reglas: Regla[]) => {
                if (!reglas || reglas.length <= 0) {
                    setWaitingButton(false);
                } else {
                    reglas.forEach((regla: Regla) => {
                        if (regla.visible) {
                            validacionDeClasificacion(regla.reglaAlternativa).then(pantalla => {
                                if (pantalla === PANTALLA_TELEFONO) {
                                    setWaitingButton(false);
                                } else {
                                    let serviciosDisponibles = { disponibilidad: disponibilidadFinal, clasificaciones: clasificaciones, coseguro: coseguroFinal };
                                    clasificacionSeleccionada = serviciosDisponibles.clasificaciones[0];
                                    let serviciosDisponiblesArray = serviciosDisponibles.clasificaciones.map((servicio) => {
                                        return servicio.descripcion;
                                    });
                                    let serviciosPosiblesSinRestriccion = ysocial?.restriccionesPlan.map((servicio) => {
                                        return servicio.descripcion;
                                    });
                                    //validacion entre restricciones y servicios disponibles 
                                    let ofrecerServicios = false;
                                    serviciosDisponiblesArray.forEach((servicio, index) => {
                                        if (serviciosPosiblesSinRestriccion.includes(servicio)) {
                                            ofrecerServicios = true;
                                        }
                                    })
                                    if (ofrecerServicios) {
                                        //existe servicio para ofrecer
                                        //envio caso a salus
                                        cargarDatosCaso();
                                    } else {
                                        //no existen servicios que se pueden ofrecer
                                        console.error("error al solicitar asistencia, no existe servicios para ofrecer");
                                        setWaitingButton(false);
                                    }
                                }
                            });
                        } else {
                            console.error("las reglas no son visibles");
                            setWaitingButton(false);
                        }
                    });
                }
            })
            .catch(error => {
                console.error(`Ocurrio un error al realizar la peticion a reglas: ${error}`);
                setError(true);
                setWaitingButton(false);
            })
    }

    if(error) {
        return <ErrorTecnicoYsocial />
    }

    if(ysocialFeedback) {
        return <FeedbackYsocial titulo={TITULO_FEEDBACK} parrafo={PARRAFO_SERVICIO_EN_CURSO} message={ERROR_SERVICIO_EN_CURSO} />
    }

    return (
        <>
            <PageWrapper
                socio={usuario}
                pageClassName="asistencia-resumen-ysocial"
                path={RESUMEN_YSOCIAL}
                handlerHandleBack={handleBack}>
                <IonRow>
                    <IonCol size='12'>
                        <Titulo
                            titulo='Antes de finalizar, asegurate de que los datos sean correctos'
                            id="titulo-asistencia-resumen"
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size='12'>
                        <CardSolicitarAsistencia
                            className='solicitar-asistencia-ysocial-card'
                            socio={capitalizeString(`${usuario.nombre} ${usuario.apellidos}`)}
                            numSocio={`N° de Socio: ${usuario.nroContrato}`}
                            nacimiento={`Fecha de nacimiento: ${usuario.fechaNacimiento.split('-').reverse().join('/')}`}
                            documento={`Documento: ${usuario.dni}`}
                        />
                    </IonCol>
                </IonRow>
                <IonRow className='contacto__ysocial_row--margin-top'>
                    <IonCol size='12'>
                        <Subtitulo
                            accordion
                            accordionOpen={contactoAccordion}
                            onClickAccordion={() => setContactoAccordion(!contactoAccordion)}
                            titulo='Datos de contacto'
                            txt16
                            id='subtitulo-resumen-contacto'
                        />
                        {contactoAccordion &&
                            <>
                                <IonRow>
                                    <IonCol size='12' className='contenido-ysocial-inputs'>
                                        <Texto1Linea
                                            left
                                            texto='Tel&eacute;fono celular'
                                        />
                                        <Texto1Linea
                                            colorBlack
                                            left
                                            texto={!usuario.telefono ? ' - ' : formatearNumeroInputCelular(usuario.telefono)}
                                            txt16
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='12' className='contenido-ysocial-inputs' >
                                        <Texto1Linea
                                            left
                                            texto='Email'
                                        />
                                        <Texto1Linea
                                            colorBlack
                                            left
                                            texto={!usuario.email ? ' - ' : usuario.email}
                                            txt16
                                        />
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </IonCol>
                </IonRow>
                <Separador />
                <IonRow>
                    <IonCol size='12'>
                        <Subtitulo
                            accordion
                            accordionOpen={direccionAccordion}
                            onClickAccordion={() => setDireccionAccordion(!direccionAccordion)}
                            titulo='Datos de ubicaci&oacute;n'
                            txt16
                            id='subtitulo-resumen-ysocial-ubicacion'
                        />
                        {direccionAccordion &&
                            <>
                                <IonRow className=''>
                                    <IonCol size='12' className='contenido-ysocial-inputs'>
                                        <Texto1Linea
                                            left
                                            texto='Domicilio'
                                        />
                                        <Texto1Linea
                                            colorBlack
                                            left
                                            texto={capitalizeString(formatDirection(dirSeleccionada))}
                                            txt16
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='12' className='contenido-ysocial-inputs'>
                                        <Texto1Linea
                                            left
                                            texto='Información adicional'
                                        />
                                        <Texto1Linea
                                            colorBlack
                                            left
                                            texto={dirSeleccionada.observaciones ? dirSeleccionada.observaciones : '-'}
                                            txt16
                                        />
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </IonCol>
                </IonRow>

                <Separador />

                <IonRow>
                    <IonCol size='12' >
                        <Subtitulo
                            accordion
                            accordionOpen={atencionAccordion}
                            onClickAccordion={() => setAtencionAccordion(!atencionAccordion)}
                            titulo='Datos de la atenci&oacute;n'
                            txt16
                            id='subtitulo-resumen-atencion'
                        />
                        {atencionAccordion &&
                            <>
                                <IonRow>
                                    <IonCol size='12' className='contenido-inputs'>
                                        <Texto1Linea
                                            left
                                            texto='Síntomas'
                                        />
                                        <Texto1Linea
                                            colorBlack
                                            left
                                            texto={showSintoma}
                                            txt16
                                        />
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </IonCol>
                </IonRow>
                <IonRow className='sticky-button-container'>
                    <IonCol size='12'>
                        <Button
                            id='resumen-ysocial-continuar'
                            primary
                            label='Solicitar la asistencia'
                            className="continuar-ysocial__button"
                            waiting={waitingButton}
                            onClick={handleBotonContinuar}
                        />
                    </IonCol>
                </IonRow>
            </PageWrapper>
        </>
    )
}

export default ResumenYsocial