import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Feedback, Modal } from "app-principal-componentes-visuales";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Contactanos from "../../components/Modals/Contactanos/Contactanos";
import { HOME, TITULO_INFORMACION_IMPORTANTE } from "../../utils/constants";
import "./ErrorPage.scss";
import { GA_KEYS, mapGA } from "../../helpers/GTM";
import { AsistenciaMedicaContext } from "../../contexts/AsistenciaMedicaContext";

interface IErrorPageProps {
	setError: any;
	isWebProp?: boolean
}

const ErrorPage = ({ setError, isWebProp }: IErrorPageProps) => {
	const history = useHistory();
	const [showModal, setShowModal] = useState(false);
	const { setupData, isWeb } = useContext(AsistenciaMedicaContext);

	useEffect(() => {
		const configGA = {
			firebase_screen: 'asistenciamedica_error_tecnico'
		}
		mapGA(GA_KEYS.ERROR, setupData, configGA)

	}, [])

	return (
		<IonPage>
			<div className="asistencia-error_page">
				<IonGrid className={`asistencia_content-container ${isWeb || isWebProp ? 'asistencia-web-mobile-padding' : ''}`}>
					<IonRow>
						<IonCol size='12'>
							<Feedback
								id='problema-tecnico'
								// pathGA='problema-tecnico'
								titulo={"Tuvimos un problema técnico"}
								parrafo={"Estamos trabajando para resolverlo. Podés contactarnos a través de Whatsapp o llamada telefónica"}
								handsPhoneError
								labelButtonCancel={"Volver a inicio"}
								onClickCancel={() => {
									setError(false)
									history.replace(HOME);
								}}
								labelButtonOk={"Contactanos"}
								onClickOk={() => setShowModal(true)}
							/>
						</IonCol>

					</IonRow>
				</IonGrid>
				<Modal
					className="modal_web"
					hideCloseBtn={false}
					//@ts-ignore
					component={<Contactanos screen={'error_page'} menorSeisMeses={false} titulo={TITULO_INFORMACION_IMPORTANTE} />}
					setShowModal={setShowModal}
					showModal={showModal}
					id="error_page-modal"
				/>
			</div>
		</IonPage>
	);
};

export default ErrorPage;
