import { SocioFamiliar } from "../interfaces/socioFamiliar.interface";
import { Socio } from "./socioPrincipal.model";

export class CasoUrgencias {
  codigoCanalEntrada: string;
  contacto = new ContactoCaso();
  firma: string;
  sintomas = new SintomasCaso();
}


export class CuestionarioCaso {
  pregunta: string;
  respuesta: string;
}

class SintomasCaso {
  comentario: string;
  cuestionario: Array<CuestionarioCaso> = new Array<CuestionarioCaso>();
}

class ContactoCaso {
  celular: string;
  telefonollamante: string;
  numeroic: number;
  numerosocio: string;
  nombre: string;
  apellidos: string;
  documento: string;
  email: string;
  motivo: string;
  codigoTipoServicio: string;
  codigoSubtipoServicio: string;
  calle: string;
  numero: string;
  observaciones: string;
  entrecallesinicio: string;
  entrecallesfin: string;
  provincia: string;
  localidad: string;
  barrio: string;
  piso: string;
  departamento: string;
  latitud: number;
  longitud: number;
  posicionexacta: boolean;
}

export enum Clasificacion {
  VB = "Visita Médica A La Brevedad",
  CMO = "Consulta Médica Online",
  AMV = "Asistencia Médica Virtual",
  COVD = "Interconsulta Covid",
  IC = "Interconsulta"
}

export enum ClasificacionGA {
  CMO = "cmo",
  VISITA_A_DOMICILIO = "visita-a-domicilio",
  COVD = "seguimiento-covid",
  GEND = "interconsulta-general"
}

export class InfoCaso {
  id: number;
  principal: Socio;
  familiar: SocioFamiliar;
  nroCaso: string;
  descripcion: string;
  codigoContacto: string;
  codigoTipoServicio: string;
  codigoSubTipoServicio: string;
  estado: string;
  fechaCreacion: Date;
  contrato: string;
  fechaInicioContrato: string;
  fechaFinContrato: string;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  estadoContrato: string;
  icono: string;
  canalEntrada: String;
  canalEntradaSalus: string;
  prestador: string;
  coseguro: number;
  excepcionCoseguro: number;
  salus: boolean;
  codigoSeguridad: string;
  estadoSalus: string;
  domicilio: string;
  email: string;
  telefono: string;
  sintomas: string;
  observacionSintoma: string;
}

export class Casos {
  casos: InfoCaso[];
  numeroIc: string
}