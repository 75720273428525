import { useState } from 'react';
import { Socio } from '../models/socioPrincipal.model';
import { Direccion } from '../interfaces/direccion.interface';
import { normalizarDireccion } from '../helpers/direccionHelper';
import { updateSocioPrincipal } from '../api/socioPrincipalService';
import { YsocialAction } from '../interfaces/global-state/ysocial.action.interface';
import { formatDirectionNormalizada, completeDirection } from '../utils/ubicacionUtils';
import { YsocialActionType } from '../interfaces/global-state/ysocial.action.type.enum';
import { CABA, CORREGIDO, DATOS_CONTACTOS_YSOCIAL, DUDOSO, NO_ENCONTRADO, SELECCIONAR_DIRECCION_YSOCIAL } from '../utils/constants';
import { useHistory } from 'react-router';

interface UseNormalizacionDireccionProps {
    socioPrincipal: Socio;
    apiRequest: (request: any) => Promise<any>;
    dispatchYsocial: (action: YsocialAction) => void
}

export const useMerlin = ( socioPrincipal, apiRequest, dispatchYsocial, direccionesSocio, fromState) => {

    const [stateMerlin, setStateMerlin] = useState<any>(undefined);
    const [direccionInputState, setDireccionInputState] = useState<Direccion | null>(null);
    const [edicionDireccion, setEdicionDireccion] = useState<boolean>(false);
    const [merlinWaiting, setMerlinWaiting] = useState<boolean>(false);
    const [showNormalizacionModal, setShowNormalizacionModal] = useState(false);
    const history = useHistory();

    /*
     * Metodo que modifica al estado de direcciones del socio principal con la direccion modificada
     */
    const modifyDirection = (direccionModificada) => {
        setEdicionDireccion(true); // booleano utilizado en el modal para saber si se quiere agregar o editar una direccion
        setMerlinWaiting(true);
        setDireccionInputState(direccionModificada);
        normalizarDireccion(direccionModificada, apiRequest) // verificar direccion ingresada con Merlin
            .then((responseMerlin) => {
                setStateMerlin(responseMerlin);
                setMerlinWaiting(false);

                if (responseMerlin.status === CORREGIDO) {
                    // Si pertenece a CABA Y los barrios no coinciden, abrir modal
                    if(responseMerlin.direccionNormalizada.localidad === CABA && 
                        responseMerlin.direccionNormalizada.barrio !== direccionModificada.barrio){
                            setShowNormalizacionModal(true);
                        }else {
                            saveEditedDirection(responseMerlin, direccionModificada);
                            //Cerrar modal de normalizacion
                            setShowNormalizacionModal(false);
                        }
                }else{
                    setShowNormalizacionModal(true);
                }
            })
            .catch((error) => {
                console.error(error);
                saveEditedDirection(null, direccionModificada);
                setMerlinWaiting(false);
            });
    };

    /*
     * Metodo para editar y guardar la direccion en el socio
     */
    const saveEditedDirection = (responseMerlin: any, direccionModificada: any) => {
        if(responseMerlin == null){
                //Si coincide el id de la direccion a editar con alguna de las direcciones del array 
                //la reemplaza con direccionModificada que es la direccion nueva
                let direction = direccionesSocio.map(direccion => {
				return direccion.id === direccionModificada.id ? direccionModificada : direccion;
			})
			updateDirection(direction);
        } else {
            //formatear la direccion de merlin al formato que se almacena en la base de datos
            let direccionFormateada = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
            //agregar a direccionFormateada los atributos departamento, observaciones, piso, provincia, y seleccionado en true
            direccionFormateada = completeDirection(direccionFormateada, direccionModificada);
            //se agrega al nuevo objeto de direccion el id de la direccion que se desea modificiar
            direccionFormateada.id = direccionModificada.id;
            //se busca la direccion en las direcciones del socio 
            //y se la reemplaza con la direccion que se desea modificar con la direccion modificada
            let direction = direccionesSocio.map(direccion => {
                return direccion.id === direccionFormateada.id ? direccionFormateada : direccion;
            })
            updateDirection(direction);
        }
    }

    /*
     * Metodo para guardar la direccion en el socio
     */
    const saveNewDirection = (responseMerlin: any, newDireccion: any) => {
        let direccionesAux: Direccion[] = direccionesSocio;
        //se cambia el estado seleccionado a false a cada una de las direcciones del socio
        direccionesAux.forEach(direccion => {
            direccion.seleccionado = false
        });
        if(responseMerlin === null) {
			direccionesAux.push(direccionInputState);
			updateDirection(direccionesAux);
        } else {
            //formatear la direccion de merlin al formato que se almacena en la base de datos
            let direccionFormateada = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
            //agregar a direccionFormateada los atributos departamento, observaciones, piso, provincia, y seleccionado en true
            direccionFormateada = completeDirection(direccionFormateada, newDireccion);
            
            //se agrega la nueva direccion que tiene estado seleccionado en true
            direccionesAux.push(direccionFormateada);
            updateDirection(direccionesAux);
        }
    }

    /*
     * Metodo que agrega al estado de direcciones del socio principal la nueva direccion
     */
    const addDirection = (newDireccion) => {
        setEdicionDireccion(false); // booleano utilizado en el modal para saber si se quiere agregar o editar una direccion
        setMerlinWaiting(true);
        setDireccionInputState(newDireccion);
        normalizarDireccion(newDireccion, apiRequest)  // verificar direccion ingresada con Merlin
            .then((responseMerlin) => {
                setStateMerlin(responseMerlin);
                setMerlinWaiting(false);

                if (responseMerlin.status === CORREGIDO ) {
                    // Si pertenece a CABA Y los barrios no coinciden, abrir modal
                    if(responseMerlin.direccionNormalizada.localidad === CABA && 
                        responseMerlin.direccionNormalizada.barrio !== newDireccion.barrio) {
                            setShowNormalizacionModal(true);
                    } else {
                        saveNewDirection(responseMerlin, newDireccion);
                        //Cerrar modal de normalizacion
                        setShowNormalizacionModal(false);
                    }
                } else {
                    setShowNormalizacionModal(true);
                }
            })
            .catch((error) => {
                console.error(error);
                saveNewDirection(null, newDireccion);
                setMerlinWaiting(false);
            });
    };

    /*
     * Metodo que se encarga de normalizar la direccion ingresada por el formulario utilizando la api de Merlin
     * solo cuando los estados son distintos a CO 
     */
    const handleNormalizacion = (data: any) => {
        if (stateMerlin.status === DUDOSO) {
            normalizarDireccion({
                barrio: direccionInputState?.barrio || '',
                calle: data.calle,
                localidad: direccionInputState?.localidad || '',
                altura: direccionInputState?.altura || '',
                provincia: direccionInputState?.provincia || '',
            }, apiRequest)
                .then((responseMerlin) => {
                    let direccionFormateada: Direccion = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
                    direccionFormateada = completeDirection(direccionFormateada, direccionInputState as Direccion);
                    let direccionesAux: Direccion[] = direccionesSocio;
                    direccionesAux.forEach(direccion => direccion.seleccionado = false);
                    direccionesAux.push(direccionFormateada);
                    updateDirection(direccionesAux);
                });
        } 
        if (stateMerlin.status === NO_ENCONTRADO) {
            //Si llega un estado NE oculta el modal y vuelve al formulario
			setShowNormalizacionModal(false);
		}
        if (stateMerlin.status === CORREGIDO) {
            saveNewDirection(stateMerlin, direccionInputState);
            setShowNormalizacionModal(false);
        }
    };

    /*
     * Metodo que se encarga de normalizar la direccion ingresada por el formulario utilizando la api de Merlin
     * solo cuando los estados son distintos a CO 
     */
    const handleNormalizacionEditar = (data) => {
		if (stateMerlin.status === DUDOSO) {
			normalizarDireccion({
				barrio: direccionInputState.barrio,
				calle: data.calle,
				localidad: direccionInputState.localidad,
				altura: direccionInputState.altura,
				provincia: direccionInputState.provincia
			}, apiRequest)
				.then((responseMerlin) => {
					let direccionFormateada: Direccion = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
					direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
					direccionFormateada.id = direccionInputState.id;
                    let direccionesAux: Direccion[] = direccionesSocio;
					let direction = direccionesAux.map(direccion => {
						return direccion.id === direccionFormateada.id ? direccionFormateada : direccion;
					})
					updateDirection(direction);
				})
		}
		if (stateMerlin.status === NO_ENCONTRADO) {
            //Si llega un estado NE oculta el modal y vuelve al formulario
			setShowNormalizacionModal(false);
		}
        if (stateMerlin.status === CORREGIDO) {
            saveEditedDirection(stateMerlin, direccionInputState);
            //Cerrar modal de normalizacion y formulario de domicilio
            setShowNormalizacionModal(false);
        }

	}
    
    const updateDirection = (direcciones: Direccion[]) => {
        let socio = socioPrincipal;
        socio.direcciones = direcciones;
        updateSocioPrincipal(socioPrincipal, apiRequest)
            .then(response => {
                dispatchYsocial({
                    type: YsocialActionType.actualizarDireccion,
                    direcciones: response.data.direcciones
                });
                
            })
            .catch(error => {
                console.error(`Hubo un error al persistir el socio principal ${error}`);
            });
        //ver el state y navegar
        setShowNormalizacionModal(false);
        if(fromState === "seleccionardomicilio" || fromState === "datosubicacion"){
            history.push(SELECCIONAR_DIRECCION_YSOCIAL);
        } else if (fromState === "datoscontactosysocial"){
            history.push(DATOS_CONTACTOS_YSOCIAL);
        }
    };

    //cuando omitis
    const handleCloseModal = () => {
        if (stateMerlin.status === DUDOSO) {
			setShowNormalizacionModal(false);
		} else {
            saveNewDirection(null, direccionInputState);
		}
    }

    //cuando omitis editando
    const handleCloseModalEdit = () => {
        if (stateMerlin.status === DUDOSO) {
			setShowNormalizacionModal(false);
		} else {
            saveEditedDirection(null, direccionInputState);
		}
    }

    return {
        stateMerlin,
        direccionInputState,
        edicionDireccion,
        merlinWaiting,
        modifyDirection,
        addDirection,
        handleNormalizacion,
        showNormalizacionModal,
        setShowNormalizacionModal,
        updateDirection,
        handleNormalizacionEditar,
        handleCloseModal,
        handleCloseModalEdit
    };
};
